import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { onSnapshot, query, collection } from 'firebase/firestore';
import { Button, Card, Grid, LinearProgress, Typography } from '@mui/material';
import { existsWithLength, NaNtoNum } from '@aldridge/aldg-helpers';
import { useRecoilValue, useRecoilState } from 'recoil';
import { _Clickstream, _CurrentJob, _MonthEndDate } from '../../../_Recoil/atoms';
import ErrorBoundary from '../../../_GlobalComponents/ErrorBoundary';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { getPM, getDM, getVP, getFinance } from '../../../utils/getUserWithRole';
import { firestore } from '../../../firebase';
import ActionItemsKPI from './KPIs/ActionItems/ActionItemsKPI';
import KPICard from './KPICard';
import EditKPIsCard from './EditKPIsCard';
import TalkingPoints from './KPIs/TalkingPoints';
import ContractValues from './KPIs/ContractValue/ContractValues';
import PercentCompleteKPI from './KPIs/MajorVariances/PercentCompleteKPI';
import MonthEndKPI from './KPIs/CashPosition/MonthEndKPI';
import UnderbillingKPI from './KPIs/CashPosition/UnderbillingKPI';
import ARKPI from './KPIs/CashPosition/ARKPI';
import { SumCostDetails } from '../ProjectStatus/calculationHelpers';
import { getProjectedTotal } from '../ProjectStatus/getValues';
import RetentionKPI from './KPIs/CashPosition/RetentionKPI';
import ContingencyKPI from './KPIs/MajorVariances/ContingencyKPI';
import { StatusContext } from '../../../providers/StatusProvider';
import ClickstreamModal from './ClickstreamModal';
import AgendaCard from './AgendaCard';
import CashFlowTiesOutKPI from './KPIs/CashPosition/CashFlowTiesOutKPI';

const Agenda = (props) => {
  const { jctdscid } = props;
  const { budgets, forecasting, contractValues } = useContext(StatusContext);
  const [users, setUsers] = useState([]);
  const [activeTask, setActiveTask] = useState(undefined);
  const [clickstreamModalName, setClickstreamModalName] = useState(undefined);
  const [allClickstreams, setAllClickstreams] = useState({});
  const [agendaTalkingPoints, setAgendaTalkingPoints] = useState({ Safety: '', Operations: '' });
  const [Clickstream, setClickstream] = useRecoilState(_Clickstream);
  const Transaction = useRecoilValue(_CurrentJob);
  const MonthEndDate = useRecoilValue(_MonthEndDate);

  const dso = NaNtoNum(Transaction.OwnerPayCycle) + NaNtoNum(Transaction.ApprovalPayCycle) + NaNtoNum(Transaction.CustomerPayCycle);

  const { FirebaseTiesOut } = contractValues || {};

  const mounted = useRef(null);

  useEffect(() => {
    mounted.current = true;
    const uq = query(collection(firestore, `ENT-Jobs/${jctdscid}/UserAssignment`));
    onSnapshot(uq, (snap) => {
      const all = [];
      snap.forEach((d) => all.push(d.data()));
      if (mounted.current) setUsers(all);
    });
    return () => {
      mounted.current = false;
    };
  }, [MonthEndDate, jctdscid]);

  useEffect(() => {
    mounted.current = true;
    if (Clickstream.activeTask >= 0) setActiveTask(Clickstream.activeTask);
    onSnapshot(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${MonthEndDate}/Agenda`), (snap) => {
      snap.docs.forEach((d) => {
        const agendaData = d.data();
        const agendaName = d.id;

        switch (agendaName) {
          case 'TalkingPoints': {
            if (mounted.current && existsWithLength(agendaData)) setAgendaTalkingPoints(agendaData);
            break;
          }
          case 'Clickstream': {
            if (mounted.current && existsWithLength(agendaData)) setAllClickstreams(agendaData);
            break;
          }
          default:
            break;
        }
      });
    });
    return () => {
      mounted.current = false;
    };
  }, []);

  const getPMd = () => getPM(users) || 'Project Manager';
  const getDMd = () => getDM(users) || 'Division Manager / Project Executive';
  const getVPd = () => getVP(users) || 'VP/SVP';
  const getFd = () => getFinance(users) || 'CFO/EVP';

  const totalPercentComplete = NaNtoZero(
    SumCostDetails(structuredClone(forecasting), undefined, 'CostToDate') / getProjectedTotal(structuredClone(forecasting))
  );

  const agendaList = [
    {
      name: 'Job-specific Safety',
      description: 'Safety, exposures on site, recent safety incidents, good catches, or near misses.',
      presenter: getPMd(),
      facilitator: getVPd(),
      order: 0,
      kpis: [{ name: 'Safety Talking Points', jsx: <TalkingPoints jctdscid={jctdscid} /> }]
    },
    {
      name: 'Action Item Review',
      description: 'Discuss action items.',
      presenter: getDMd(),
      facilitator: getVPd(),
      order: 1,
      kpis: [{ name: `Action Items`, jsx: <ActionItemsKPI jctdscid={jctdscid} /> }]
    },
    {
      name: 'Operational Status',
      description: `Overview of milestones, scopes in progress, customer experience, risks to finish, etc... "What do you need to succeed?"`,
      presenter: getPMd(),
      facilitator: getVPd(),
      order: 2,
      kpis: [{ name: 'Ops Talking Points', jsx: <TalkingPoints jctdscid={jctdscid} type='Operations' /> }]
    },
    {
      name: 'Contract Value',
      description: 'Review approved and pending change orders.',
      presenter: getPMd(),
      facilitator: getVPd(),
      order: 3,
      kpis: [
        {
          name: 'Approved Contract Value',
          jsx: (
            <ContractValues cmCOs={budgets} cmForecast={structuredClone(forecasting)} MonthEndContractNumbers={contractValues} jctdscid={jctdscid} />
          )
        },
        {
          name: 'Pending Contract Value',
          jsx: (
            <ContractValues
              cmCOs={budgets}
              cmForecast={structuredClone(forecasting)}
              jctdscid={jctdscid}
              MonthEndContractNumbers={contractValues}
              pending
            />
          )
        }
      ]
    },
    {
      name: 'Percent Complete Cost Review (Major Variances)',
      description:
        'Month over month significant adjustments, Labor/Material/Subcontracting/Equipment/Other costs, Labor/Equipment rates, forecasting, JPAC',
      presenter: getPMd(),
      facilitator: getFd(),
      order: 4,
      kpis: [
        { name: 'Productive Rates', jsx: <PercentCompleteKPI forecast={structuredClone(forecasting)} changeOrders={budgets} type='P' /> },
        { name: 'Non Productive Rates', jsx: <PercentCompleteKPI forecast={structuredClone(forecasting)} changeOrders={budgets} type='NP' /> },
        { name: 'Equipment Rates', jsx: <PercentCompleteKPI forecast={structuredClone(forecasting)} changeOrders={budgets} /> },
        { name: 'Contingencies', jsx: <ContingencyKPI jctdscid={jctdscid} /> }
      ]
    },
    {
      name: 'Cash Position',
      description: 'Open A/R and collection forecast, underbilling, cash flow.',
      presenter: getPMd(),
      facilitator: getFd(),
      order: 5,
      kpis: [
        { name: 'Month End Cash', jsx: <MonthEndKPI jctdscid={jctdscid} /> },
        {
          name: '(Underbilling)/Overbilling',
          jsx: <UnderbillingKPI jctdscid={jctdscid} forecast={structuredClone(forecasting)} />
        },
        { name: `Accounts Receivable Over ${dso}`, jsx: <ARKPI jctdscid={jctdscid} /> },
        { name: 'Retention Dollar', jsx: <RetentionKPI jctdscid={jctdscid} />, show: () => totalPercentComplete >= 0.95 },
        { name: 'Cash Flow', jsx: <CashFlowTiesOutKPI FirebaseTiesOut={FirebaseTiesOut} /> }
      ]
    }
  ];

  const start = () => {
    setClickstream({ currentStep: -1, steps: [], inAgenda: true, activeTask: 0, activeTaskName: agendaList[0].name });
    setActiveTask(0);
  };

  const end = () => {
    setClickstream({ currentStep: -1, steps: [], inAgenda: false, activeTask: -1, activeTaskName: '' });
    setActiveTask(undefined);
  };

  const moveTask = (direction) => {
    switch (direction) {
      case 'f': {
        if (activeTask < agendaList.length - 1) setActiveTask(activeTask + 1);
        else end();
        break;
      }
      case 'b': {
        if (activeTask > 0) setActiveTask(activeTask - 1);
        break;
      }
      default:
        break;
    }
  };

  const startClickstream = () => {
    const clickstreamToStart = allClickstreams[agendaList[activeTask].name];
    setClickstream({ currentStep: 0, steps: clickstreamToStart, inAgenda: true, activeTask, activeTaskName: agendaList[activeTask].name });
  };

  const agendaInProgress = existsWithLength(activeTask);

  const currentAList = agendaInProgress ? agendaList.slice(activeTask).concat(agendaList.slice(0, activeTask)) : agendaList;

  return (
    <ErrorBoundary componentName='Agenda'>
      <Grid container id='container' style={{ height: 'fit-content' }}>
        <ClickstreamModal name={clickstreamModalName} setName={setClickstreamModalName} allClicks={allClickstreams} jctdscid={jctdscid} />
        <div style={{ display: 'flex', width: '100%', justifyContent: 'end', alignItems: 'center' }}>
          {agendaInProgress ? (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button onClick={() => moveTask('b')} variant='contained' className='aldg-inlineButton'>
                Previous
              </Button>
              <Button onClick={end} variant='contained' className='aldg-inlineButton' color='error'>
                End
              </Button>
              {existsWithLength(allClickstreams[agendaList[activeTask].name]) && (
                <Button color='success' variant='contained' className='aldg-inlineButton' onClick={startClickstream}>
                  Begin Clickstream
                </Button>
              )}
              <Button onClick={() => moveTask('f')} variant='contained' className='aldg-inlineButton'>
                Next
              </Button>
            </div>
          ) : (
            <Button onClick={start} variant='contained' color='success'>
              Start
            </Button>
          )}
        </div>
        {agendaInProgress && (
          <div style={{ width: '100%', padding: '8px 6px' }}>
            <LinearProgress variant='determinate' value={((activeTask + 1) / 6) * 100} />
          </div>
        )}
        <Grid item xs={7}>
          <Typography variant='h4'>Schedule</Typography>
          {currentAList.map((task, idx) => (
            <div
              role='button'
              onKeyDown={() => {}}
              tabIndex={idx}
              key={task.name}
              style={{ cursor: idx !== 0 && agendaInProgress && 'pointer' }}
              onClick={() => {
                if (agendaInProgress) setActiveTask(task.order);
              }}
            >
              <AgendaCard
                task={task}
                idx={idx}
                agendaInProgress={agendaInProgress}
                setClickstreamModalName={setClickstreamModalName}
                allClickstreams={allClickstreams}
              />
            </div>
          ))}
        </Grid>
        <Grid item xs={5} style={{ marginTop: '16px', display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h4'>KPIs</Typography>
          <Card variant='outlined' style={{ height: '100%' }}>
            {agendaInProgress ? (
              agendaList[activeTask].kpis.map((kpi) => <KPICard kpi={kpi} key={kpi.name} />)
            ) : (
              <EditKPIsCard jctdscid={jctdscid} agendaTalkingPoints={agendaTalkingPoints} />
            )}
          </Card>
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

Agenda.propTypes = {
  jctdscid: PropTypes.string.isRequired
};
Agenda.defaultProps = {};

export default Agenda;
