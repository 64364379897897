/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader, Grid, Modal, Paper, Typography } from '@mui/material';
import { doc, getDoc, setDoc, writeBatch } from 'firebase/firestore';
import { isUndefined, useFunctionCall } from '@aldridge/aldg-helpers';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import {
  faDownload,
  faCalendarArrowDown,
  faRotate,
  faUpload,
  faLock,
  faUnlock,
  faPlusMinus,
  faCalculatorSimple,
  faClockRotateLeft,
  faRotateLeft,
  faX
} from '@fortawesome/pro-regular-svg-icons';
import * as ExcelJS from 'exceljs';
import { TailSpin } from 'react-loader-spinner';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import diff from 'microdiff';
import existsWithLength from '../../../utils/existsWithLength';
import { _SecurityLevel } from '../../../_Recoil/atoms';
import fileUploadClick from '../../../utils/fileUploadClick';
import { UserContext } from '../../../providers/UserProvider';
import PromptUpload from '../../UIComponents/PromptUpload';
import { StatusContext } from '../../../providers/StatusProvider';
import getMostRecentMonth from '../../../utils/getMostRecentMonth';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { firestore } from '../../../firebase';
import ParseForecastUpload from './parseForecastUpload';
import DownloadForecast from './downloadForecast';
import JumpToButton from '../../UIComponents/JumpToButton';
import { EditorUp, AdminUp, SuperAdmin } from '../UserAssignment/checkSecurity';
import uploadAttachment from '../Attachments/uploadAttachment';
import { generateForecast, AutoCalculateForecast } from './generateForecast';

const ForecastingToolbar = (props) => {
  const {
    monthEndDate,
    jctdscid,
    rows,
    setRows,
    tableLoading,
    setUploadingForecast,
    uploadingForecast,
    pm,
    memorialized,
    reloadData,
    lastUpdated,
    canEdit
  } = props;
  const { budgets, contractValues, loadAttachments } = useContext(StatusContext);
  const SecurityLevel = useRecoilValue(_SecurityLevel);
  const [attachPrompted, setAttachedPrompted] = useState(false);
  const user = useContext(UserContext);

  const generating = useRef({ loading: false, refresh: false, previous: false });

  const [forecastDownload, setForecastDownload] = useState(false);

  const { loading, functionCall, data: refreshData, error } = useFunctionCall('RefreshForecastData', undefined, 540000000);

  const [open, setOpen] = useState(false);
  const {
    data: HistoryData,
    loading: HistoryLoading,
    error: HistoryError,
    functionCall: HistoryFunctionCall
  } = useFunctionCall('history', {}, 540000);

  const viewHistory = async () => {
    setOpen(true);
    if (!HistoryData) HistoryFunctionCall({ Collection: 'Forecasting', MonthEnd: monthEndDate, JobNumber: jctdscid });
  };

  // useEffect(() => {
  //   console.log(HistoryData, HistoryLoading, HistoryError);
  // }, [HistoryData, HistoryLoading, HistoryError]);

  useEffect(() => {
    getMostRecentMonth(jctdscid, monthEndDate).then((newMonthEnd) => {
      if ((generating.current.loading || generating.current.refresh) && !isUndefined(refreshData))
        generateForecast(jctdscid, budgets, generating, monthEndDate, newMonthEnd, refreshData, reloadData, user);
    });
  }, [refreshData]);

  const RefreshForecastData = (refresh) => {
    if (refresh) generating.current.refresh = true;
    functionCall({
      jctdscid,
      monthEndDate
    });
  };

  // const InsertCostsByCode = async (codeCosts) => {
  //   setUploadingCostsByCodes(true);
  //   let batch = writeBatch(firestore);
  //   for (let i = 0; i < codeCosts.length; i++) {
  //     if (i !== 0 && i % 500 === 0) {
  //       // eslint-disable-next-line no-await-in-loop
  //       await batch.commit();
  //       batch = writeBatch(firestore);
  //     }
  //     const c = codeCosts[i];
  //     const docRef = doc(firestore, `ENT-Jobs/${jctdscid}/JobCostPostings/${c.id}`);
  //     batch.set(docRef, c, { merge: true });
  //   }
  //   toast.success(`${codeCosts.length} Costs Updated`);
  //   await batch.commit();
  //   setUploadingCostsByCodes(false);
  // };

  // useEffect(() => {
  //   if (!CostsByCodeLoading) {
  //     if (existsWithLength(CostsByCodeData)) {
  //       InsertCostsByCode(CostsByCodeData);
  //     }
  //     if (existsWithLength(CostsByCodeError)) {
  //       toast.error(CostsByCodeError, { autoClose: 10000 });
  //     }
  //   }
  // }, [CostsByCodeData, CostsByCodeLoading, CostsByCodeError]);

  const CalculateCostToComplete = () => {
    setUploadingForecast(true);
    getMostRecentMonth(jctdscid, monthEndDate).then((newMonthEnd) => {
      AutoCalculateForecast(jctdscid, monthEndDate, newMonthEnd, reloadData, setUploadingForecast, user);
    });
  };

  const GenerateForecastStart = () => {
    generating.current.loading = true;
    RefreshForecastData(false);
  };

  const UploadForecast = async (toSave) => {
    // Update all the forecasts (only update the To Complete Fields)
    const callback = (filedata) => {
      const workbook = new ExcelJS.Workbook();
      setUploadingForecast(true);
      workbook.xlsx.load(filedata).then(() => {
        ParseForecastUpload(workbook, monthEndDate, jctdscid, setUploadingForecast, rows, setRows, user).then(() => {
          // RecalcVariances();
          reloadData();
        });
      });
    };
    const fileCallback = (files) => {
      if (toSave) uploadAttachment(files, jctdscid, true, loadAttachments, () => {}, user, monthEndDate, 'Forecasting');
      setAttachedPrompted(false);
    };
    fileUploadClick('uploadWBS', callback, fileCallback);
  };

  const Memorialize = () => {
    setDoc(
      doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}`),
      {
        Memorialized: !memorialized ? 'true' : 'false',
        MemorializedBy: user.email,
        MemorializedDate: new Date().toJSON(),
        MemorializedOnPage: 'Forecasting'
      },
      { merge: true }
    );
  };

  const validMonthEnd = () => !memorialized && monthEndDate !== null && monthEndDate !== '' && !tableLoading && canEdit;

  const tableNames = ['Labor', 'Material', 'Subcontractor', 'Equipment', 'Other', 'Summary'];

  const ReCalcVariances = async () => {
    const LaborCostToComplete = (params) => NaNtoZero(params.HoursToComplete) * NaNtoZero(params.CostHrToComplete);
    const TotalCostAtCompletion = (params) => NaNtoZero(params.CostToComplete) + NaNtoZero(params.CostToDate);
    const LaborTotalCostAtCompletion = (params) => LaborCostToComplete(params) + NaNtoZero(params.CostToDate);
    const LaborVarianceAtCompletion = (params) => NaNtoZero(params.LaborTotal) - LaborTotalCostAtCompletion(params);
    const VarianceAtCompletion = (params) => NaNtoZero(params.Total) - TotalCostAtCompletion(params);

    const uRows = JSON.parse(JSON.stringify(rows));

    let batch = writeBatch(firestore);
    for (let i = 0; i < uRows.length; i++) {
      if (i !== 0 && i % 500 === 0) {
        // eslint-disable-next-line no-await-in-loop
        await batch.commit();
        batch = writeBatch(firestore);
      }
      const u = uRows[i];
      if (u.CostType === 'L') {
        u.CostToComplete = LaborCostToComplete(u);
        u.TotalCostAtCompletion = LaborTotalCostAtCompletion(u);
        u.VarianceAtCompletion = LaborVarianceAtCompletion(u);
      } else {
        u.TotalCostAtCompletion = TotalCostAtCompletion(u);
        u.VarianceAtCompletion = VarianceAtCompletion(u);
      }

      const docRef = doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/Forecasting/${u.id}`);
      batch.set(docRef, u, { merge: true });
    }
    await batch.commit();
  };

  const RollBackChange = async (recordPath, record) => {
    const docRef = doc(firestore, recordPath);
    const docData = await getDoc(docRef);
    const data = docData.data();
    console.log('Original Data', data);
    diff(record.old_data || {}, record.data || {}, { cyclesFix: false }).map(async (cr) => {
      const field = cr.path?.[0];
      const value = cr.oldValue;
      data[field] = value;
    });
    console.log('Rolled back Data', data);
    setDoc(docRef, data);
  };

  const showChangeRecord = (record) => {
    if (!record || record[1].length === 0) return null;
    const changes = [];
    const docName = `ENT-Jobs/1.0.${record[1]?.[0].document_name.split('.')[2]}`;
    for (let i = 0; i < record[1].length; i++) {
      const r = record[1]?.[i];
      changes.push(diff(record[1]?.[i]?.old_data || {}, record[1]?.[i]?.data || {}, { cyclesFix: false }));
    }
    return record[1].map((r, i) => (
      <Accordion>
        <AccordionSummary>
          <div>{`${docName.replaceAll('/', ' / ')}`}</div>
          <div>{`Timestamp: ${dayjs(r.timestamp?.value).isValid() ? dayjs(r.timestamp?.value).format('MMM D, YYYY h:mm A') : '[none]'}`}</div>
          <ToolTipIconButton
            icon={faRotateLeft}
            tooltipText='Roll Back to this Record'
            onClick={() => {
              RollBackChange(docName, r);
              toast.info('Rolling back...');
            }}
            placement='bottom-start'
            style={{ zIndex: 20000 }}
          />
        </AccordionSummary>
        <AccordionDetails>
          <div>
            {diff(r.old_data || {}, r.data || {}, { cyclesFix: false }).map(
              (cr) => (
                // { if (!['LastSynced', 'CreatedBy', 'CreatedDate', 'ModifiedDate', 'ModifiedBy'].includes(cr.path?.[0]))
                //   return (
                <Card sx={{ overflow: 'auto' }}>
                  <CardHeader title={`${cr.type}`} />
                  <CardContent>
                    <div>{`${cr.path?.[0]}:`}</div>
                    <div>{`[${cr.oldValue}] \t==>\t [${cr.value}]`}</div>
                  </CardContent>
                  {/* <CardActions>
                    <Button variant='contained' onClick={() => RollBackChange(docName, cr.path?.[0], cr.value)}>
                      Roll Back Change
                    </Button>
                  </CardActions> */}
                </Card>
              )
              //   );
              // return null;}
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
    <Grid container>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Card>
          <CardHeader
            title={
              <Grid container sx={{ borderBottom: 3, borderColor: 'primary.main' }}>
                <Grid item xs={8}>
                  <Typography variant='h5'>All Document [SUPERADMIN VIEW]</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant='h5'>TimeStamp</Typography>
                </Grid>
                <Grid item xs={1}>
                  <ToolTipIconButton
                    icon={faClockRotateLeft}
                    onClick={() => HistoryFunctionCall({ Collection: 'Forecasting', MonthEnd: monthEndDate, JobNumber: jctdscid })}
                    tooltipText='Refresh History'
                    loading={HistoryLoading}
                    placement='bottom-start'
                    style={{ zIndex: 20000 }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <ToolTipIconButton
                    icon={faX}
                    color='error'
                    onClick={() => setOpen(false)}
                    tooltipText='Close'
                    loading={HistoryLoading}
                    placement='bottom-start'
                    style={{ zIndex: 20000 }}
                  />
                </Grid>
              </Grid>
            }
          />
          <CardContent sx={{ maxHeight: 600, maxWidth: 800, overflow: 'auto' }}>
            {HistoryLoading ? <div>Loading...</div> : Object.entries(HistoryData || {}).map(showChangeRecord)}
          </CardContent>
        </Card>
      </Modal>
      {loading || uploadingForecast ? (
        <>
          {typeof error !== 'undefined' ? (
            <div>{`Something went wrong...${error}`}</div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', whiteSpace: 'pre-wrap' }}>
              <TailSpin height={56} />
              <span>
                {uploadingForecast
                  ? 'Forecast uploading...'
                  : 'This process may take a few minutes to properly update all the cost codes for your job.'}
              </span>
            </div>
          )}
        </>
      ) : null}
      <Grid item xs={12}>
        <Paper variant='outlined' className='aldg-formButtons' style={{ border: 'none' }}>
          <Grid style={{ width: '100%', alignSelf: 'left' }}>
            <Grid item xs={12} style={{ width: '100%', alignSelf: 'left' }}>
              {memorialized && (
                <div style={{ width: '100%', alignSelf: 'center', whiteSpace: 'pre-wrap' }}>
                  <b>This month has been memorialized. No more edits may occur.</b>
                </div>
              )}
            </Grid>
            <Grid item xs={12} style={{ width: '100%', alignSelf: 'left' }}>
              {lastUpdated !== '' ? (
                <Paper variant='outlined' style={{ width: '100%', alignSelf: 'center', whiteSpace: 'pre-wrap', fontSize: 18 }}>
                  <b>Forecast was last updated:</b> <i>{dayjs(lastUpdated).format('MMM D, YYYY h:mm A')}</i>
                </Paper>
              ) : null}
            </Grid>
          </Grid>
          {SuperAdmin(SecurityLevel) && (
            <ToolTipIconButton
              loading={HistoryLoading}
              tooltipText='View Record Changes'
              onClick={viewHistory}
              icon={faClockRotateLeft}
              color='gray'
            />
          )}
          {AdminUp(SecurityLevel) && (
            <ToolTipIconButton
              loading={loading || uploadingForecast}
              tooltipText={!memorialized ? 'Memorialize Forecast Month' : 'Unlock Forecast Month'}
              onClick={Memorialize}
              icon={!memorialized ? faLock : faUnlock}
              color='gray'
            />
          )}
          {validMonthEnd() && EditorUp(SecurityLevel) && rows.length > 0 && (
            <>
              <ToolTipIconButton
                loading={loading || uploadingForecast}
                tooltipText='Refresh Forecast Data'
                onClick={() => {
                  RefreshForecastData(true);
                }}
                icon={faRotate}
              />
              {attachPrompted ? (
                <div style={{ margin: '-22px 0px 0px 8px' }}>
                  <PromptUpload upload={UploadForecast} setAttachedPrompted={setAttachedPrompted} />
                </div>
              ) : (
                <ToolTipIconButton
                  loading={loading || uploadingForecast}
                  tooltipText='Upload Forecast Spreadsheet'
                  color='success'
                  onClick={() => setAttachedPrompted(true)}
                  icon={faUpload}
                />
              )}
            </>
          )}
          {existsWithLength(monthEndDate) && existsWithLength(rows) && (
            <ToolTipIconButton
              loading={loading || uploadingForecast || forecastDownload}
              tooltipText='Download Forecast Spreadsheet'
              onClick={() =>
                DownloadForecast(
                  rows.filter((a) => !a.Subtotal),
                  monthEndDate,
                  pm,
                  jctdscid,
                  contractValues,
                  setForecastDownload
                )
              }
              icon={faDownload}
            />
          )}
          {validMonthEnd() && EditorUp(SecurityLevel) && rows.length === 0 && (
            <>
              {/* <ToolTipIconButton
                loading={loading || uploadingForecast}
                tooltipText={`Generate Forecast for ${monthEndDate}`}
                color='success'
                onClick={GenerateForecastStart}
                icon={faChartLineUp}
              /> */}
              <ToolTipIconButton
                loading={loading || uploadingForecast}
                tooltipText='Generate Forecast From Previous Month'
                color='success'
                onClick={() => {
                  generating.current.previous = true;
                  GenerateForecastStart();
                }}
                icon={faCalendarArrowDown}
              />
            </>
          )}
          {SuperAdmin(SecurityLevel) && rows.length !== 0 && (
            <>
              <ToolTipIconButton
                loading={loading || uploadingForecast}
                tooltipText={`ReCalc Variances for ${monthEndDate}`}
                color='success'
                onClick={ReCalcVariances}
                icon={faPlusMinus}
              />
            </>
          )}
          {validMonthEnd() && EditorUp(SecurityLevel) && rows.length > 0 && (
            <ToolTipIconButton
              loading={loading || uploadingForecast}
              tooltipText={`Calculate CTC for ${monthEndDate}`}
              color='success'
              onClick={CalculateCostToComplete}
              icon={faCalculatorSimple}
            />
          )}
          <JumpToButton tableNames={tableNames} distinguisher='ForecastingTable' extraTxt=' PICCs ' />
        </Paper>
      </Grid>
    </Grid>
  );
};

ForecastingToolbar.propTypes = {
  monthEndDate: PropTypes.string,
  jctdscid: PropTypes.string.isRequired,
  tableLoading: PropTypes.bool,
  rows: PropTypes.array,
  setRows: PropTypes.func,
  setUploadingForecast: PropTypes.func,
  uploadingForecast: PropTypes.bool,
  pm: PropTypes.string,
  memorialized: PropTypes.bool,
  reloadData: PropTypes.func,
  lastUpdated: PropTypes.string,
  canEdit: PropTypes.bool.isRequired
};
ForecastingToolbar.defaultProps = {
  monthEndDate: '2010-12-31',
  tableLoading: false,
  rows: [],
  setRows: () => {},
  setUploadingForecast: () => {},
  uploadingForecast: false,
  pm: '-',
  memorialized: true,
  reloadData: () => {},
  lastUpdated: ''
};

export default ForecastingToolbar;
