import React from 'react';
import { Link } from 'react-router-dom';

export default {
  field: 'LinkTo',
  headerName: 'Link',
  valueGetter: (params) =>
    params.row.LinkTo?.includes('ProjectStatusReport') ? params.row.LinkTo?.replace('ProjectStatusReport', 'StatusReport') : params.row.LinkTo || '',
  renderCell: (params) => (
    <Link to={params.value || '/'} style={{ wordBreak: 'break-word' }}>
      {params.value}
    </Link>
  ),
  flex: 40
};
