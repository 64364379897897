import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
// import { NaNtoNum } from '@aldridge/aldg-helpers';
import InputToggleNumber from '../../UIComponents/InputToggleNumber';
import InputToggleDate from '../../UIComponents/InputToggleDate';
import InputToggle from '../../UIComponents/InputToggle';
import InputToggleSelect from '../../UIComponents/InputToggleSelect';
import { formatAsPercent } from '../../UIComponents/format';
import { _Defaults, _SecurityLevel } from '../../../_Recoil/atoms';
import { nonMuiThemes } from '../../../theme';
import TextDisplay from '../../UIComponents/TextDisplay';
import { AdminUp } from '../UserAssignment/checkSecurity';

const Accounting = (props) => {
  const { Transaction, onChange, editing, allowWrap } = props;
  const [Defaults, setDefaults] = useRecoilState(_Defaults);
  const SecurityLevel = useRecoilValue(_SecurityLevel);

  const defaultUpdateonChange = (value, name) => {
    const newDefault = JSON.parse(JSON.stringify(Defaults));
    newDefault[name] = value;
    setDefaults(newDefault);
    onChange(value, name);
  };

  return (
    <Grid container>
      <TextDisplay label='Certified Payroll' value={Transaction.CertifiedPayroll || ''} />
      <InputToggleSelect
        allowWrap={allowWrap}
        label='Method of Transmission'
        name='MethodOfTransmission'
        value={Transaction.MethodOfTransmission}
        onChange={onChange}
        edit={editing}
        options={[
          { value: 'Paper', label: 'Paper' },
          { value: 'LCP Tracker', label: 'LCP Tracker' },
          { value: 'Other', label: 'Other' }
        ]}
      />
      {Transaction?.MethodOfTransmission?.toLowerCase().includes('other') && (
        <InputToggle
          allowWrap={allowWrap}
          label='Other Details'
          name='MethodOfTransmissionOther'
          value={Transaction.MethodOfTransmissionOther}
          onChange={onChange}
          edit={editing}
        />
      )}
      <InputToggle
        allowWrap={allowWrap}
        label='Tax Exempt Number'
        name='TaxExemptNumber'
        value={Transaction.TaxExemptNumber || ''}
        onChange={onChange}
        edit={editing}
      />
      <InputToggle
        allowWrap={allowWrap}
        label='OCIP Detail'
        name='OCIPDetail'
        value={Transaction.OCIPDetail || ''}
        onChange={onChange}
        edit={editing}
      />
      <InputToggleNumber
        allowWrap={allowWrap}
        label='Contract GL Rate'
        name='ContractGLRate'
        value={Transaction.ContractGLRate || Defaults.ContractGLRate}
        onChange={defaultUpdateonChange}
        edit={editing}
        displayFunc={formatAsPercent}
        min={0}
        max={1}
      />
      <InputToggleNumber
        allowWrap={allowWrap}
        label='Tooling Allocation %'
        name='ToolingAllocation'
        value={Transaction.ToolingAllocation}
        onChange={onChange}
        edit={editing}
        displayFunc={typeof Transaction.ToolingAllocation === 'number' ? formatAsPercent : () => 'TBD'}
        min={0}
        max={1}
      />
      <InputToggleDate label='Billing Cycle' name='BillingCycleDate' value={Transaction.BillingCycleDate} onChange={onChange} edit={editing} />
      <InputToggleNumber
        allowWrap={allowWrap}
        label='Approval Pay Cycle'
        name='ApprovalPayCycle'
        value={Transaction.ApprovalPayCycle || 0}
        onChange={onChange}
        edit={editing}
        min={0}
      />
      <InputToggleNumber
        allowWrap={allowWrap}
        label='Owner Pay Cycle'
        name='OwnerPayCycle'
        value={Transaction.OwnerPayCycle || 0}
        onChange={onChange}
        edit={editing}
        min={0}
      />
      <InputToggleNumber
        allowWrap={allowWrap}
        label='Customer Pay Cycle'
        name='CustomerPayCycle'
        value={Transaction.CustomerPayCycle || 0}
        onChange={onChange}
        edit={editing}
        min={0}
      />
      <InputToggle
        allowWrap={allowWrap}
        label='Owner Payment Website'
        name='OwnerPaymentWebsite'
        value={Transaction.OwnerPaymentWebsite || ''}
        onChange={onChange}
        edit={editing}
      />
      <InputToggle
        allowWrap={allowWrap}
        label='Prime Contract Number'
        name='PrimeContractNumber'
        value={Transaction.PrimeContractNumber || ''}
        onChange={onChange}
        edit={editing}
      />
      <InputToggleNumber
        allowWrap={allowWrap}
        label='Variance'
        name='Variance'
        value={Defaults.Variance}
        displayFunc={(val) => `${val * 100} %`}
        onChange={defaultUpdateonChange}
        edit={editing && AdminUp(SecurityLevel)}
      />
      <InputToggleSelect
        allowWrap={allowWrap}
        label='Waiver Requirements'
        name='WaiverRequirements'
        value={Transaction.WaiverRequirements}
        onChange={onChange}
        edit={editing}
        options={[
          { value: 'No waivers required', label: 'No waivers required' },
          { value: 'Attachment A', label: 'Attachment A' },
          { value: 'Attachment B or equivalent list', label: 'Attachment B or equivalent list' },
          {
            value: 'Neither attachment accepted, needs legal review/duress letter',
            label: 'Neither attachment accepted, needs legal review/duress letter'
          },
          { value: 'Statutory Lien Waiver State', label: 'Statutory Lien Waiver State' }
        ]}
      />
      <div style={nonMuiThemes.divider} />
      <div style={{ marginTop: '-22px', fontSize: '20px', paddingBottom: '6px' }}>Cash Flow</div>
      <InputToggleDate
        allowWrap={allowWrap}
        label='Cash Flow Completion Date'
        name='CashFlowCompletionDate'
        value={Transaction.CashFlowCompletionDate}
        onChange={onChange}
        edit={editing}
      />
      <InputToggleNumber
        allowWrap={allowWrap}
        label='Retention %'
        name='RetentionPercent'
        value={Defaults.RetentionPercent}
        onChange={defaultUpdateonChange}
        edit={editing}
        displayFunc={formatAsPercent}
        min={0}
        max={1}
      />
      <InputToggle
        allowWrap={allowWrap}
        label='Retention Terms'
        name='RetentionTerms'
        value={Transaction.RetentionTerms || Defaults.RetentionTerms}
        onChange={defaultUpdateonChange}
        edit={editing}
      />
      <InputToggleNumber
        allowWrap={allowWrap}
        label='DSO Model'
        name='DSOModel'
        value={Defaults.DSOModel}
        displayFunc={(val) => `${val} Months`}
        onChange={defaultUpdateonChange}
        edit={editing}
      />
      <InputToggleNumber
        allowWrap={allowWrap}
        label='Labor/Equipment/Other'
        name='LaborEquipOther'
        value={Defaults.LaborEquipOther}
        displayFunc={(val) => `${val} Months`}
        onChange={defaultUpdateonChange}
        edit={editing}
      />
      <InputToggleNumber
        allowWrap={allowWrap}
        label='Materials'
        name='Materials'
        value={Defaults.Materials}
        displayFunc={(val) => `${val} Months`}
        onChange={defaultUpdateonChange}
        edit={editing}
      />
      <InputToggleNumber
        allowWrap={allowWrap}
        label='Subcontractors'
        name='Subs'
        value={Defaults.Subs}
        displayFunc={(val) => `${val} Months`}
        onChange={defaultUpdateonChange}
        edit={editing}
      />
    </Grid>
  );
};

Accounting.propTypes = {
  Transaction: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func,
  editing: PropTypes.bool,
  allowWrap: PropTypes.bool
};
Accounting.defaultProps = {
  onChange: () => {},
  editing: false,
  allowWrap: false
};

export default Accounting;
