/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import { getDoc, setDoc, doc, collection } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { faCopy, faFileDownload, faSave, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Files, InputSelect, InputText, InputTextarea } from '@aldridge/aldg-data-components';
import { UserContext } from '../../../providers/UserProvider';
import InputToggle from '../../UIComponents/InputToggle';
import InputToggleNumber from '../../UIComponents/InputToggleNumber';
import { firestore } from '../../../firebase';
import TooltipIconButton from '../../UIComponents/TooltipIconButton';
import DownloadAbstractTemplate from './DownloadAbstractTemplate';
import existsWithLength from '../../../utils/existsWithLength';

const ContractAbstract = (props) => {
  const { Transaction, jctdscid, abstractId, history } = props;
  const user = useContext(UserContext);
  const [copyJob, setCopyJob] = useState('1.0.');
  const [open, setOpen] = useState(false);
  const [abstractRecord, setAbstractRecord] = useState({
    id: 'new',
    CreatedDate: new Date(),
    CreatedBy: user.email || 'No email',
    ModifiedDate: '',
    ModifiedBy: '',
    data: {
      AdministrativeRequirements: {
        SafetyPlanRequired: { ContractSection: '', SubSection: '', Notes: '', YesNo: '' },
        QualityPlanRequired: { ContractSection: '', SubSection: '', Notes: '', YesNo: '' },
        HaveDBEPlanCommitments: { ContractSection: '', SubSection: '', Notes: '', YesNo: '' },
        OwnerApproveSubs: { ContractSection: '', SubSection: '', Notes: '', YesNo: '' },
        ResponsibleForPermits: { ContractSection: '', SubSection: '', Notes: '' },
        OrderOfPrecedence: { ContractSection: '', SubSection: '', Notes: '' }
      },
      ScheduleItems: {
        ProjectStartDate: { ContractSection: '', SubSection: '', Notes: '' },
        MilestonesDatesListed: { ContractSection: '', SubSection: '', Notes: '', YesNo: '' },
        ProjectCompletionDateListed: { ContractSection: '', SubSection: '', Notes: '', YesNo: '' },
        SubstantialCompletionDefined: { ContractSection: '', SubSection: '', Notes: '' },
        OwnerAcceptWork: { ContractSection: '', SubSection: '', Notes: '' },
        CPMScheduleRequired: { ContractSection: '', SubSection: '', Notes: '', YesNo: '' },
        JobHasLiquidatedDamages: { ContractSection: '', SubSection: '', Notes: '', YesNo: '', LiquidatedDamagesCost: '' }
      },
      PaymentTerms: {
        FlowChartPaymentProcess: { ContractSection: '', SubSection: '', Notes: '', FileUpload: '' },
        BillingCycle: { ContractSection: '', SubSection: '', Notes: '' },
        CertPayrollRequired: { ContractSection: '', SubSection: '', Notes: '', YesNo: '' },
        RetentionHeld: { ContractSection: '', SubSection: '', Notes: '', YesNo: '' }
      },
      Submittals: {
        LengthOwnerReview: { ContractSection: '', SubSection: '', Notes: '' },
        ProvideCustomerDeliverableLog: { ContractSection: '', SubSection: '', Notes: '' }
      },
      DelaysAndDamages: {
        PaidForDelays: { ContractSection: '', SubSection: '', Notes: '', YesNo: '' },
        CanAddDaysForDelays: { ContractSection: '', SubSection: '', Notes: '', YesNo: '' },
        NotifyCustomerTime: { ContractSection: '', SubSection: '', Notes: '' },
        ChangeOrderProcess: { ContractSection: '', SubSection: '', Notes: '', FileUpload: '' },
        CustomerAccelerateSchedule: { ContractSection: '', SubSection: '', Notes: '', YesNo: '' },
        EncounterUnforeseenCondition: { ContractSection: '', SubSection: '', Notes: '' },
        ReferenceSubsurfaceConditions: { ContractSection: '', SubSection: '', Notes: '', YesNo: '' },
        ConsequentialDamagesListed: { ContractSection: '', SubSection: '', Notes: '', YesNo: '' },
        PaidForWeatherDays: { ContractSection: '', SubSection: '', Notes: '', YesNo: '' }
      },
      Warranty: {
        ProjectWarranty: { ContractSection: '', SubSection: '', Notes: '' }
      },
      Notes: ''
    }
  });
  const tables = [
    {
      name: { title: 'Administrative Requirements', header: 'AdministrativeRequirements', needYesNo: true },
      description: [
        { YesNoSelect: true, uploadFile: false, question: 'Is a Safety Plan Required?', header: 'SafetyPlanRequired' },
        { YesNoSelect: true, uploadFile: false, question: 'Is a Quality Plan Required?', header: 'QualityPlanRequired' },
        { YesNoSelect: true, uploadFile: false, question: 'Do we have DBE Plan Commitments?', header: 'HaveDBEPlanCommitments' },
        { YesNoSelect: true, uploadFile: false, question: 'Does The Owner have to approve Subs?', header: 'OwnerApproveSubs' },
        { YesNoSelect: false, uploadFile: false, question: 'Who is responsible for permits?', header: 'ResponsibleForPermits' },
        { YesNoSelect: false, uploadFile: false, question: 'What is the order of precedence for docs?', header: 'OrderOfPrecedence' }
      ]
    },
    {
      name: { title: 'Schedule Items', header: 'ScheduleItems', needYesNo: true },
      description: [
        { YesNoSelect: false, uploadFile: false, question: 'Project Start Date', header: 'ProjectStartDate' },
        {
          YesNoSelect: true,
          uploadFile: false,
          question: 'Are Milestones Dates Listed?',
          header: 'MilestonesDatesListed'
        },
        {
          YesNoSelect: true,
          uploadFile: false,
          question: 'Is a Project Completion Date Listed?',
          header: 'ProjectCompletionDateListed'
        },
        {
          YesNoSelect: false,
          uploadFile: false,
          question: 'How is Substantial Completion Defined?',
          header: 'SubstantialCompletionDefined'
        },
        {
          YesNoSelect: false,
          uploadFile: false,
          question: 'How does the Owner Accept our Work?',
          header: 'OwnerAcceptWork'
        },
        {
          YesNoSelect: true,
          uploadFile: false,
          question: 'Is a CPM Schedule Required?',
          header: 'CPMScheduleRequired'
        },
        {
          YesNoSelect: true,
          uploadFile: false,
          question: 'Does the Job have Liquidated Damages?',
          header: 'JobHasLiquidatedDamages',
          conditionalQuestion: 'What is it? ($/Day)',
          conditionalHeader: 'LiquidatedDamagesCost'
        }
      ]
    },
    {
      name: { title: 'Payment Terms', header: 'PaymentTerms', needYesNo: true },
      description: [
        { YesNoSelect: false, uploadFile: true, question: 'Upload Payment Flow Chart', header: 'FlowChartPaymentProcess' },
        { YesNoSelect: false, uploadFile: false, question: 'What is the billing cycle?', header: 'BillingCycle' },
        { YesNoSelect: true, uploadFile: false, question: 'Is Cert Payroll Required?', header: 'CertPayrollRequired' },
        { YesNoSelect: true, uploadFile: false, question: 'Is Retention Going to be Held?', header: 'RetentionHeld' }
      ]
    },
    {
      name: { title: 'Submittals', header: 'Submittals', needYesNo: false },
      description: [
        { YesNoSelect: false, uploadFile: false, question: 'How long does Owner have to Review?', header: 'LengthOwnerReview' },
        {
          YesNoSelect: false,
          uploadFile: false,
          question: 'Do we have to provide Customer a Deliverable Log?',
          header: 'ProvideCustomerDeliverableLog'
        }
      ]
    },
    {
      name: { title: 'Delays and Damages', header: 'DelaysAndDamages', needYesNo: true },
      description: [
        { YesNoSelect: true, uploadFile: false, question: 'Are we able to be Paid for Delays?', header: 'PaidForDelays' },
        {
          YesNoSelect: true,
          uploadFile: false,
          question: 'Are we able to have days added to our Schedule for Delays?',
          header: 'CanAddDaysForDelays'
        },
        { YesNoSelect: false, uploadFile: false, question: 'How Quickly do we Need to Notify the Customer?', header: 'NotifyCustomerTime' },
        { YesNoSelect: false, uploadFile: true, question: 'Map out the Change Order Process', header: 'ChangeOrderProcess' },
        { YesNoSelect: true, uploadFile: false, question: 'Can the Customer Accelerate our Schedule?', header: 'CustomerAccelerateSchedule' },
        {
          YesNoSelect: false,
          uploadFile: false,
          question: 'What happens when we encounter an Unforeseen Condition?',
          header: 'EncounterUnforeseenCondition'
        },
        {
          YesNoSelect: true,
          uploadFile: false,
          question: 'Does the Contract Reference Subsurface conditions?',
          header: 'ReferenceSubsurfaceConditions'
        },
        { YesNoSelect: true, uploadFile: false, question: 'Are Consequential Damages Listed?', header: 'ConsequentialDamagesListed' },
        {
          YesNoSelect: true,
          uploadFile: false,
          question: 'Can we be paid or have our scheduled increased due to Weather days?',
          header: 'PaidForWeatherDays'
        }
      ]
    },
    {
      name: { title: 'Warranty', header: 'Warranty', needYesNo: false },
      description: [{ YesNoSelect: false, uploadFile: false, question: 'What is the Project Warranty?', header: 'ProjectWarranty' }]
    }
  ];

  useEffect(() => {
    let mounted = true;
    if (abstractId !== 'new' && mounted) {
      getDoc(doc(firestore, `ENT-Jobs/${jctdscid}/ContractAbstract`, abstractId)).then((d) => {
        if (!d.exists() && mounted) {
          toast.error('This contract abstract does not exist in this job or has been deleted. Select a different contract abstract.');
          history.push(`/Job/${jctdscid}/ContractAbstract`);
        } else if (mounted) setAbstractRecord((PREV) => ({ ...PREV, ...d.data() }));
      });
    }
    return () => {
      mounted = false;
    };
  }, [abstractId]);

  const onChange = (val, nm) => {
    const newRecord = { ...abstractRecord };
    if (abstractRecord.id === 'new') {
      newRecord.CreatedDate = new Date();
      newRecord.CreatedBy = user.email || 'No email';
    }
    const value = val.target ? val.target.value : val;
    const name = val.target ? val.target.name : nm;

    if (name.split('-').length > 2) {
      const type = name.split('-')[0];
      const index = name.split('-')[1];
      const subIndex = name.split('-')[2];
      newRecord.data[type][index][subIndex] = value;
    } else {
      newRecord.data[name] = value;
    }
    newRecord.ModifiedDate = new Date();
    newRecord.ModifiedBy = user.email || 'No email';
    // console.log('updated', newRecord);
    setAbstractRecord(newRecord);
  };

  const handleSave = async (jobId, rec) => {
    const record = { ...rec };
    if (record.id === 'new') {
      const newId = doc(collection(firestore, `ENT-Jobs/${jobId}/ContractAbstract`)).id;
      history.replace(`/Job/${jctdscid}/ContractAbstract/${newId}/ContractAbstractView`);
      record.id = newId;
    }
    const recordId = record.id;

    const dRef = doc(firestore, `ENT-Jobs/${jobId}/ContractAbstract`, recordId);
    setDoc(dRef, JSON.parse(JSON.stringify(record)), { merge: true });
    toast.success(`Contract Abstract has been saved for job [${jobId}]. If you are finished, you may close the record.`);
  };

  const CopyToAnotherJob = () => {
    const newRecord = {
      ...abstractRecord,
      oldId: abstractRecord.id,
      id: 'new',
      CopiedFromJob: jctdscid,
      CopiedBy: user.email || 'No email',
      CopiedDate: new Date(),
      ModifiedDate: new Date(),
      ModifiedBy: user.email || 'No email'
    };
    if (copyJob !== '1.0.' && copyJob.length === 10) {
      try {
        handleSave(copyJob, newRecord);
        setOpen(false);
        setCopyJob('1.0.');
      } catch (e) {
        toast.error('There was an error saving the abstract to the new job. Please ensure the job number is valid.');
      }
    } else {
      toast.error('Please enter a valid job number to copy to.');
    }
  };

  const dataLabel = (obj, addCost = false) => {
    const yesnoFormat =
      obj.YesNo === 'Yes' && addCost ? `Yes, $${obj.LiquidatedDamagesCost !== '' ? obj.LiquidatedDamagesCost : ' - - '}/Day` : obj.YesNo;
    const string = `${yesnoFormat}${obj.Notes !== '' ? `: ${obj.Notes}` : ''} ${
      obj.ContractSection !== '' || obj.SubSection !== '' ? `\n[Contract Section ${obj.ContractSection}.${obj.SubSection}]` : ''
    }`;
    return string;
  };

  return (
    <Paper variant='outlined' className='aldg-field'>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>Type in the job you would like to copy to:</DialogTitle>
        <DialogContent>
          <InputText name='JobNumber' label='Job Number' value={copyJob} onChange={(e) => setCopyJob(e)} />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'right' }}>
          <Button
            onClick={() => {
              setCopyJob('1.0.');
              setOpen(false);
            }}
            variant='contained'
            color='error'
            style={{ marginRight: '50px', marginBottom: '20px' }}
            autoFocus
          >
            Cancel
          </Button>
          <Button onClick={CopyToAnotherJob} variant='contained' color='success' style={{ marginRight: '30px', marginBottom: '20px' }}>
            Copy
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: 'contents',
            padding: '0px',
            textAlign: 'center'
          }}
        >
          <div
            style={{
              position: 'sticky',
              top: '-1px',
              left: 0,
              right: 0,
              width: '100%',
              padding: '8px',
              display: 'flex',
              justifyContent: 'flex-end',
              zIndex: 100,
              backgroundColor: 'white'
            }}
          >
            <TooltipIconButton onClick={() => handleSave(jctdscid, abstractRecord)} color='success' title='Save' icon={faSave} />
            <TooltipIconButton
              color='error'
              onClick={() => {
                history.push(`/Job/${jctdscid}/ContractAbstract`);
              }}
              icon={faTimes}
              title='Cancel'
            />
            <TooltipIconButton
              color='default'
              onClick={() => DownloadAbstractTemplate(Transaction, abstractRecord, jctdscid, tables)}
              icon={faFileDownload}
              title='Download Template'
            />
            <TooltipIconButton color='default' onClick={() => setOpen(true)} icon={faCopy} title='Copy to Another Job' />
          </div>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant='h5'> Aldridge Contract Abstract </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputToggleNumber edit={false} name='AldridgeJobNumber' value={Transaction.id} label='Aldridge Job Number' />
        </Grid>
        <Grid item xs={12}>
          <InputToggle edit={false} name='Owner' value={Transaction.CosentialOwner} label='Owner' />
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={6} sx={{ backgroundColor: '#ededed' }}>
            <Grid container>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography variant='h5'> Major Contract Priorities </Typography>
              </Grid>
              <Grid item xs={12}>
                <InputToggle
                  edit={false}
                  name='ConsequentialDamagesAllowed'
                  onChange={onChange}
                  value={dataLabel(abstractRecord.data.DelaysAndDamages.ConsequentialDamagesListed)}
                  label='Are Consequential Damages allowed?'
                />
              </Grid>
              <Grid item xs={12}>
                <InputToggle
                  edit={false}
                  name='HowPaidForChanges'
                  onChange={onChange}
                  value={dataLabel(abstractRecord.data.DelaysAndDamages.PaidForDelays)}
                  label='How are we being paid for changes or impacts?'
                />
              </Grid>
              <Grid item xs={12}>
                <InputToggle
                  edit={false}
                  name='HasLiquidatedDamages'
                  onChange={onChange}
                  value={dataLabel(abstractRecord.data.ScheduleItems.JobHasLiquidatedDamages, true)}
                  label='Does the contract have Liquidated Damages?'
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {tables?.map((rows) => (
            <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: 24 }} align='center'>
                    {rows.name.title}
                  </TableCell>
                </TableRow>
                <TableRow variant='head'>
                  <TableCell> Description </TableCell>
                  <TableCell> {rows.name.needYesNo ? 'Yes/No' : ''} </TableCell>
                  <TableCell align='left'> Notes </TableCell>
                  <TableCell align='left'> Contract Section </TableCell>
                  <TableCell align='left'> Sub Section </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.description?.map((row) => (
                  <TableRow key={row.header}>
                    <TableCell sx={{ width: '30%' }} component='th' scope='row'>
                      {row.uploadFile ? (
                        <Grid container>
                          <Grid item xs={12}>
                            {row.question}
                          </Grid>
                          <Grid item xs={12}>
                            {abstractRecord.id === 'new' && (
                              <Typography color='red' variant='body1'>
                                Please save the abstract before uploading files!
                              </Typography>
                            )}
                            <Files
                              folder={`${jctdscid}-ContractAbstract/${abstractRecord.id}/${rows.name.header}/${row.header}`}
                              // firebaseConfig={config}
                              sizes={[{ folder: 'thumbnails', size: '200x200', useForImages: true }]}
                              type='block'
                              emulatorPort={9999}
                              canAdd={abstractRecord.id !== 'new'}
                              canDelete={abstractRecord.id !== 'new'}
                              multiple
                              defaultOpen
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        row.question
                      )}
                    </TableCell>
                    {rows.name.needYesNo ? (
                      <TableCell sx={{ width: '10%' }} align='left'>
                        {row.YesNoSelect ? (
                          <InputSelect
                            name={`${rows.name.header}-${row.header}-YesNo`}
                            options={[
                              { value: 'Yes', label: 'Yes' },
                              { value: 'No', label: 'No' }
                            ]}
                            variant='outlined'
                            onChange={onChange}
                            value={abstractRecord.data[rows.name.header][row.header].YesNo}
                          />
                        ) : (
                          <Grid container />
                        )}
                        {existsWithLength(row.conditionalHeader) && abstractRecord.data[rows.name.header][row.header].YesNo === 'Yes' && (
                          <Grid container>
                            <Grid item xs={12}>
                              {row.conditionalQuestion}
                            </Grid>
                            <Grid item xs={12}>
                              <InputToggle
                                edit
                                name={`${rows.name.header}-${row.header}-${row.conditionalHeader}`}
                                onChange={onChange}
                                value={abstractRecord.data[rows.name.header][row.header][row.conditionalHeader]}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell align='left' />
                    )}
                    <TableCell sx={{ width: '40%' }} align='left'>
                      <InputToggle
                        edit
                        name={`${rows.name.header}-${row.header}-Notes`}
                        onChange={onChange}
                        value={abstractRecord.data[rows.name.header][row.header].Notes}
                      />
                    </TableCell>
                    <TableCell sx={{ width: '10%' }} align='left'>
                      <InputToggle
                        edit
                        name={`${rows.name.header}-${row.header}-ContractSection`}
                        onChange={onChange}
                        value={abstractRecord.data[rows.name.header][row.header].ContractSection}
                      />
                    </TableCell>
                    <TableCell sx={{ width: '10%' }} align='left'>
                      <InputToggle
                        edit
                        name={`${rows.name.header}-${row.header}-SubSection`}
                        onChange={onChange}
                        value={abstractRecord.data[rows.name.header][row.header].SubSection}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ))}
          <Grid item xs={12}>
            <InputTextarea edit name='Notes' value={abstractRecord.data.Notes} onChange={onChange} label='Notes' rows={4} fullWidth />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

ContractAbstract.propTypes = {
  Transaction: PropTypes.object.isRequired,
  jctdscid: PropTypes.string.isRequired,
  abstractId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};
ContractAbstract.defaultProps = {};

export default ContractAbstract;
