/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { AldgQuickAdd, InputAutocomplete, InputSelect } from '@aldridge/aldg-data-components';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { getDocs, query, setDoc, doc, deleteDoc, collection } from 'firebase/firestore';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { useRecoilValue } from 'recoil';
import existsWithLength from '../../../utils/existsWithLength';
import ErrorBoundary from '../../../_GlobalComponents/ErrorBoundary';
import { _Clickstream, _SecurityLevel } from '../../../_Recoil/atoms';
import { config, firestore } from '../../../firebase';
import { PMUp } from './checkSecurity';

const UserAssignmentJob = (props) => {
  const { Transaction, jctdscid } = props;
  const SecurityLevel = useRecoilValue(_SecurityLevel);
  const Clickstream = useRecoilValue(_Clickstream);
  const [userList, setUserList] = useState([]);
  const apiRef = useGridApiRef();
  const [currentRecord, setCurrentRecord] = useState({
    Email: '',
    name: '',
    nameDisplay: '',
    role: '',
    isGlobal: false,
    id: ''
  });

  const inAgenda = Clickstream.inAgenda;

  const getRowId = (params) => {
    if (existsWithLength(params.Division) && existsWithLength(params.Email)) return `${params.Division[0]}-${params.Email}-${params.role}`;
    return params.id;
  };

  useEffect(() => {
    let mounted = true;
    getDocs(query(collection(firestore, `ENT-Jobs/${jctdscid}/UserAssignment`))).then((docs) => {
      const allUsers = [];
      docs.forEach((d) => {
        allUsers.push(d.data());
      });
      if (mounted) {
        const upperRoles = allUsers.filter((x) => !['Viewer', 'Editor'].includes(x.role));
        const ReaderWriters = allUsers.filter((x) => ['Viewer', 'Editor'].includes(x.role));
        setUserList(upperRoles.concat(ReaderWriters));
      }
    });

    return () => {
      mounted = false;
    };
  }, [Transaction.id]);

  useEffect(() => {
    if (existsWithLength(apiRef.current)) apiRef.current.setColumnVisibilityModel({ Job: false, CosentialJobName: false });
  }, []);

  const getJobAccess = () => {
    getDocs(query(collection(firestore, `ENT-Jobs/${jctdscid}/UserAssignment`))).then((docs) => {
      const allUsers = [];
      docs.forEach((d) => {
        const userData = d.data();
        userData.id = d.id;
        try {
          userData.JobDisplay = d.ref.path.split('/')[1];
        } catch (err) {
          userData.JobDisplay = '';
        } finally {
          allUsers.push(userData);
        }
      });

      const fixedAllUsers = allUsers?.sort((a, b) => (a.nameDisplay || '')?.localeCompare(b.nameDisplay || ''));
      const upperRoles = fixedAllUsers.filter((x) => !['Viewer', 'Editor'].includes(x.role));
      const ReaderWriters = fixedAllUsers.filter((x) => ['Viewer', 'Editor'].includes(x.role));
      setUserList(upperRoles.concat(ReaderWriters));
    });
  };

  const refreshData = () => {
    if (existsWithLength(apiRef.current)) apiRef.current.setColumnVisibilityModel({ isGlobal: false });
    getJobAccess();
  };

  useEffect(() => {
    refreshData();
  }, []);

  const deleteDocument = async (row, Recordid) => {
    console.log('Recordid', Recordid, row);
    await deleteDoc(doc(firestore, `ENT-Jobs/${jctdscid}/UserAssignment`, Recordid));
    refreshData();
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      valueGetter: (params) => params.row.nameDisplay?.split('[')[0],
      flex: 50,
      canEdit: true,
      inputType: 'InputAutocomplete',
      config,
      firestoreOptions: {
        valueKey: 'prtmstid',
        collection: 'ENT-Employees',
        orderBy: 'PreferredLastName',
        method: 'get',
        where: [['Status', '==', 'A']]
      },
      hideable: false,
      optionDisplay: (opt) => `${opt.PreferredFirstName || ''} ${opt.MiddleName || ''} ${opt.PreferredLastName || ''} [${opt.UniversalID || ''}]`,
      autorecordFields: ['Email'],
      onChangeDependents: ['Job', 'CosentialJobName']
    },
    {
      field: 'Email',
      headerName: 'Email',
      valueGetter: (params) => params.row.Email,
      flex: 50,
      hideable: false
    },
    {
      field: 'role',
      headerName: 'Role',
      valueGetter: (params) => params.row.role,
      canEdit: 'true',
      inputType: 'InputSelect',
      options: [
        { value: 'Editor', label: 'Editor' },
        { value: 'Viewer', label: 'Viewer' }
      ],
      hideable: false,
      flex: 50
    },
    {
      field: 'Delete',
      headerName: '',
      renderCell: (params) => {
        if (params.row.ecmsRole !== true) {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <ToolTipIconButton
                shape='circle'
                tooltipText='Delete'
                color='error'
                icon={faTrash}
                onClick={() => {
                  const id = params.row.id;
                  deleteDocument(params.row, id);
                }}
              />
            </div>
          );
        }
        return null;
      },
      flex: 40
    }
  ];

  const notVP = useCallback(
    (params) =>
      // a secondary render that renders an undefined row object causes non selectable rows to be selected
      !PMUp(params?.row?.role),
    []
  );

  const UpdateRow = (row) => {
    setCurrentRecord({
      name: row.name,
      Email: row.Email,
      nameDisplay: row.nameDisplay,
      role: row.role,
      id: row.id
    });
  };

  const handleSave = () => {
    console.log('currentRecord', currentRecord);
    setDoc(
      doc(firestore, `ENT-Jobs/${jctdscid}/UserAssignment`, `${Transaction.id}-${currentRecord.Email}`),
      {
        Email: currentRecord.Email,
        name: currentRecord.name,
        nameDisplay: currentRecord.nameDisplay,
        role: currentRecord.role,
        isGlobal: false,
        Job: [Transaction.id],
        JobDisplay: Transaction.id,
        CosentialJobName: Transaction.CosentialJobName,
        id: `${Transaction.id}-${currentRecord.Email}-${currentRecord.role}`,
        ModifiedDate: new Date()
      },
      {
        merge: true
      }
    );
    setCurrentRecord((prev) => ({ ...prev, name: '', Email: '', role: '' }));
    refreshData();
  };

  return (
    <ErrorBoundary componentname='User Assignment (JOB)'>
      <Grid container>
        <AldgQuickAdd
          DataGridProps={{
            apiRef,
            pagination: true,
            getRowId,
            onCellClick: (cell, event) => {
              if (cell.field === 'Delete') {
                event.preventDefault();
                event.stopPropagation();
              }
              if (cell.row.ecmsRole) {
                event.preventDefault();
                event.stopPropagation();
              }
            }
          }}
          canAdd={PMUp(SecurityLevel) && !inAgenda}
          anchor='right'
          updateRow={UpdateRow}
          columns={columns}
          rows={userList}
          handleSave={handleSave}
          form={
            <>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={1}>
                <Grid item xs={12}>
                  <InputAutocomplete
                    firestoreOptions={{
                      valueKey: 'prtmstid',
                      collection: 'ENT-Employees',
                      orderBy: 'PreferredLastName',
                      method: 'get',
                      where: [['Status', '==', 'A']]
                    }}
                    label='Name'
                    name='name'
                    onChange={(x, y, z, a) => {
                      setCurrentRecord((prev) => ({
                        ...prev,
                        name: x,
                        nameDisplay: `${a?.[0]?.PreferredFirstName} ${a?.[0]?.MiddleName} ${a?.[0]?.PreferredLastName} [${a?.[0]?.Title}]` || '',
                        Email: a?.[0]?.Email || ''
                      }));
                    }}
                    optionDisplay={(opt) => `${opt.PreferredFirstName} ${opt.MiddleName} ${opt.PreferredLastName} [${opt.Title}]`}
                    value={currentRecord.name || []}
                    disablePortal
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputSelect
                    options={[
                      { value: 'Editor', label: 'Editor' },
                      { value: 'Viewer', label: 'Viewer' }
                    ]}
                    fullWidth
                    label='Role'
                    // name={currentRecord.nameDisplay}
                    value={currentRecord.role}
                    onChange={(x) => {
                      setCurrentRecord((prev) => ({ ...prev, role: x.target.value }));
                    }}
                    id='fullWidth'
                  />
                </Grid>
              </Grid>
            </>
          }
        />
        {/* <AldgDataGrid
          pageSizeOptions={[10, 20, 30]}
          apiRef={apiRef}
          columns={columns}
          editable={PMUp(SecurityLevel) && !inAgenda}
          getRowId={getUserId}
          FirebaseProps={{
            firebaseConfig: config,
            collection: `ENT-Jobs/${jctdscid}/UserAssignment`
          }}
          isRowSelectable={notVP}
          rowCanBeEdited={notVP}
          rows={userList}
        /> */}
      </Grid>
    </ErrorBoundary>
  );
};

UserAssignmentJob.propTypes = {
  Transaction: PropTypes.object.isRequired,
  jctdscid: PropTypes.string.isRequired
};

UserAssignmentJob.defaultProps = {};

export default UserAssignmentJob;
