import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, ImageList, ImageListItem, Paper, Typography, ImageListItemBar, Box, CircularProgress } from '@mui/material';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { faDownload, faEye, faGrid, faList, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { useRecoilValue } from 'recoil';
import { InputSelect } from '@aldridge/aldg-data-components';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import FileUploadBtn from '../../UIComponents/FileUploadBtn';
import { UserContext } from '../../../providers/UserProvider';
import { _Clickstream, _MonthEndDate, _SecurityLevel } from '../../../_Recoil/atoms';
import uploadAttachment from './uploadAttachment';
import MenuCardData from '../Dashboard/MenuCardData';
import deleteFile from './deleteAttachment';
import { StatusContext } from '../../../providers/StatusProvider';

const Attachments = (props) => {
  const { jctdscid } = props;
  const Role = useRecoilValue(_SecurityLevel);
  const Clickstream = useRecoilValue(_Clickstream);
  const { attachments, loadAttachments } = useContext(StatusContext);
  const user = useContext(UserContext);

  const inAgenda = Clickstream.inAgenda;

  const emptyCats = [{ title: 'Other Files', href: 'Attachments', files: [] }].concat(
    MenuCardData.filter((card) => !['Attachments', 'Rollback Month'].includes(card.title) && card.securityLevel(Role, user.beta)).map((card) => ({
      title: card.title,
      href: card.href,
      files: []
    }))
  );

  const [loading, setLoading] = useState(false);
  const [uploadingTo, setUploadingTo] = useState('');
  const [categories, setCategories] = useState(emptyCats);
  const [isListView, setIsListView] = useState({
    isList: true,
    cols: 1,
    rowHeight: 50,
    gap: 30,
    mLeft: { marginLeft: 70 },
    imgStyle: { width: '3%', height: '100%' },
    buttons: 'square'
  });
  const mounted = useRef(null);
  const MonthEndDate = useRecoilValue(_MonthEndDate);
  const docExcelTypes = [
    ['.doc', '.docx', '.xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
  ];

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    setCategories(() => emptyCats);
    if (existsWithLength(attachments)) {
      const filteredFiles = attachments?.sort((a, b) => dayjs(a.uploadedDate).isBefore(dayjs(b.uploadedDate)));
      filteredFiles.forEach((file) => {
        let currentCategoryIdx = categories.findIndex((cat) => cat.href === file.LinkTo?.split('/')?.at(-1));
        if (currentCategoryIdx < 1) currentCategoryIdx = 0;
        if (mounted.current)
          setCategories((prevCat) => {
            const newCat = JSON.parse(JSON.stringify(prevCat));
            if (newCat[currentCategoryIdx].files.findIndex((f) => f.name === file.name) < 0) newCat[currentCategoryIdx].files.push(file);
            newCat.forEach((cat) => cat.files.sort((a, b) => b.name.localeCompare(a.name)));
            return newCat;
          });
      });
    }
  }, [mounted.current, attachments, jctdscid]);

  const startDownload = (file) => {
    const link = document.createElement('a');
    if (docExcelTypes.includes(file.type)) {
      link.href = file.downloadURL;
      link.download = file.name;
      link.click();
    } else {
      document.body.appendChild(link);
      link.style = 'display: none';

      fetch(file.downloadURL)
        .then((response) => response.blob())
        .then((myBlob) => {
          const objectURL = URL.createObjectURL(myBlob);
          link.href = objectURL;
          link.download = file.name;
          link.click();
        })
        .catch((err) => toast.error(err.message));
    }
  };

  const previewFile = (file) => {
    const DEFAULT_OPTIONS = {
      toolbar: 'yes',
      location: 'yes',
      directories: 'yes',
      status: 'yes',
      menubar: 'yes',
      scrollbars: 'yes',
      resizable: 'yes',
      width: 500,
      height: 400,
      top: (o, w) => (w.innerHeight - o.height) / 2 + w.screenY,
      left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX
    };
    if (file.NeedsDriveViewer)
      window.open(`https://drive.google.com/viewerng/viewer?url=${encodeURIComponent(file.downloadURL)}`, '', DEFAULT_OPTIONS);
    else window.open(file.downloadURL, '', DEFAULT_OPTIONS);
  };

  const toggleListView = () => {
    if (isListView.isList === true) {
      setIsListView({
        isList: false,
        cols: 5,
        rowHeight: 200,
        gap: 0,
        mLeft: { marginLeft: 0 },
        imgStyle: { width: '60%', height: '100%' },
        buttons: 'circle'
      });
    }
    if (isListView.isList === false) {
      setIsListView({
        isList: true,
        cols: 1,
        rowHeight: 50,
        gap: 30,
        mLeft: { marginLeft: 70 },
        imgStyle: { width: '3%', height: '100%' },
        buttons: 'square'
      });
    }
  };

  /* `https://drive.google.com/viewerng/viewer?url=${encodeURIComponent(FileViewed.downloadURL)}`
  `https://doc.google.com/gview?url=${encodeURIComponent(FileViewed.downloadURL)}` */

  return (
    <Grid container>
      <Box
        sx={{
          '& .MuiImageListItemBar-subtitle': {
            whiteSpace: 'initial'
          },
          '& .MuiImageList-root': {
            width: '100%'
          },
          width: '100%'
        }}
      >
        <Grid item xs={12}>
          {!inAgenda && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'end',
                marginTop: '-60px',
                zIndex: 1000,
                position: 'relative',
                paddingBottom: '15px',
                alignItems: 'center'
              }}
            >
              <div style={{ width: '25%' }}>
                <InputSelect
                  name='category'
                  label='Upload to Category'
                  options={categories.map((cat) => ({ value: cat.href, label: cat.title }))}
                  value={uploadingTo}
                  onChange={(e) => {
                    setUploadingTo(e.target.value);
                  }}
                />
              </div>
              <FileUploadBtn
                onUpload={(event) => uploadAttachment(event, jctdscid, mounted, loadAttachments, setLoading, user, MonthEndDate, uploadingTo)}
              />
              <ToolTipIconButton
                icon={isListView.isList === true ? faGrid : faList}
                tooltipText={isListView.isList === true ? 'Grid View' : 'List View'}
                placement='right'
                onClick={toggleListView}
              />
            </div>
          )}
          <Paper variant='outlined' className='aldg-field'>
            <Grid container>
              <Typography variant='body2' style={{ paddingBottom: '10px', fontStyle: 'italic' }}>
                Any uploaded attachment will appear here. An attachment can be included in a specific section by changing the drop down.
                <br />
                If the file does not open on intial popup, please close the popup and try again or refresh the popup.
              </Typography>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <CircularProgress size={100} />
                </div>
              ) : (
                categories
                  .filter((cat) => existsWithLength(cat.files))
                  .map((cat) => (
                    <Grid item xs={12} key={cat.title}>
                      <ImageList cols={isListView.cols} rowHeight={isListView.rowHeight} gap={isListView.gap}>
                        <ImageListItem key='Subheader' cols={isListView.cols} style={{ height: 'fit-content' }}>
                          <div
                            style={{
                              paddingBottom: '8px',
                              borderBottom: '3px solid black',
                              marginBottom: '4px'
                            }}
                          >
                            <Typography variant='h4'>{cat.title}</Typography>
                          </div>
                        </ImageListItem>
                        {existsWithLength(cat.files) ? (
                          cat.files.map((file) => (
                            <ImageListItem key={file.name}>
                              <div>
                                <img src={file.url} srcSet={file.url} alt={file.name} style={isListView.imgStyle} />
                                <ImageListItemBar
                                  style={isListView.mLeft}
                                  title={file.name}
                                  subtitle={`Uploaded by ${file.uploadedBy || 'Unknown'} on ${
                                    dayjs(file.uploadedDate).format('llll') || ' some day.'
                                  }`}
                                  actionIcon={
                                    isListView.isList === true ? (
                                      <Grid container>
                                        <ToolTipIconButton
                                          shape={isListView.buttons}
                                          icon={faEye}
                                          tooltipText='View File'
                                          placement='right'
                                          onClick={() => previewFile(file)}
                                        />
                                        <ToolTipIconButton
                                          shape={isListView.buttons}
                                          icon={faDownload}
                                          tooltipText='Download File'
                                          placement='right'
                                          onClick={() => startDownload(file)}
                                          color='success'
                                        />
                                        {!inAgenda ? (
                                          <ToolTipIconButton
                                            shape={isListView.buttons}
                                            icon={faTrash}
                                            color='error'
                                            placement='right'
                                            tooltipText='Delete File'
                                            onClick={() => deleteFile(file, jctdscid, loadAttachments)}
                                          />
                                        ) : null}
                                      </Grid>
                                    ) : (
                                      <div>
                                        <ToolTipIconButton
                                          shape={isListView.buttons}
                                          icon={faEye}
                                          tooltipText='View File'
                                          placement='right'
                                          onClick={() => previewFile(file)}
                                        />
                                        <ToolTipIconButton
                                          shape={isListView.buttons}
                                          icon={faDownload}
                                          tooltipText='Download File'
                                          placement='right'
                                          onClick={() => startDownload(file)}
                                          color='success'
                                        />
                                        <ToolTipIconButton
                                          shape={isListView.buttons}
                                          icon={faTrash}
                                          color='error'
                                          placement='right'
                                          tooltipText='Delete File'
                                          onClick={() => deleteFile(file, jctdscid, loadAttachments)}
                                        />
                                      </div>
                                    )
                                  }
                                />{' '}
                              </div>
                            </ImageListItem>
                          ))
                        ) : (
                          <ImageListItem key='no files' cols={4} style={{ height: 'max-content' }}>
                            <Typography variant='subtitle1' style={{ width: '100%', textAlign: 'center' }}>
                              No files right now! Use the upload button in the top right to add some.
                            </Typography>
                          </ImageListItem>
                        )}
                      </ImageList>
                    </Grid>
                  ))
              )}
            </Grid>
          </Paper>
        </Grid>
      </Box>
    </Grid>
  );
};

Attachments.propTypes = {
  jctdscid: PropTypes.string.isRequired
};
Attachments.defaultProps = {};

export default Attachments;
