/* eslint-disable no-underscore-dangle */
import { atom, selector } from 'recoil';
import dayjs from 'dayjs';
import getMostRecentMonth from '../utils/getMostRecentMonth';
import getStatusMonth from '../utils/getStatusMonth';
import getCostDiffValues from '../utils/getCostDiffValues';

const SaveInitedOffline =
  () =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem('OfflineInited');
    // eslint-disable-next-line
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      if (typeof newValue === 'undefined') {
        localStorage.removeItem('OfflineInited');
      } else {
        localStorage.setItem('OfflineInited', JSON.stringify(newValue));
      }
    });
  };
const localStorageEffect =
  (project, key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(project);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue)[key] || undefined);
    }

    onSet((newValue) => {
      if (typeof newValue === 'undefined') {
        localStorage.removeItem(project);
      } else {
        const lsi = JSON.parse(localStorage.getItem(project));
        localStorage.setItem(
          project,
          JSON.stringify({
            ...lsi,
            [key]: newValue
          })
        );
      }
    });
  };

export const _HeaderTitle = atom({
  key: 'HeaderTitle',
  default: 'Project Information Dashboard'
});

export const _CurrentJob = atom({
  key: 'CurrentJob',
  default: {
    id: '',
    CreatedDate: '',
    CreatedBy: '',
    ModifiedDate: '',
    ModifiedBy: '',
    CertifiedPayroll: '',
    ClinicsAndHospitals: '',
    ContractType: '',
    CosentialClient: '',
    CosentialDescriptionOfWork: '',
    CosentialJobName: '',
    CosentialOriginalCompletionDate: '',
    CosentialOwner: '',
    CosentialProjectNumber: '',
    CosentialStartDate: '',
    CosentialRevisedCompletionDate: '',
    CurrentContractValue: '',
    CustomerContractNumber: '',
    DeliveryMethod: '',
    EcmsClosedDate: '',
    EcmsCompanyNumber: '',
    EcmsCustomerName: '',
    EcmsCustomerNumber: '',
    EcmsDivision: '',
    EcmsDivisionName: '',
    EcmsJobDescription: '',
    EcmsJobNumber: '',
    EcmsOriginalCompletionDate: '',
    EcmsRevisedCompletionDate: '',
    EcmsStartDate: '',
    EcmsStatus: '',
    EcmsSubJobDescription: '',
    EcmsSubJobNumber: '',
    EmergencyContacts: '',
    EstimateNumber: '',
    FirstFieldDayOfWork: '',
    JobHazardAnalysis: '',
    MethodOfTransmission: '',
    MethodOfTransmissionOther: '',
    OCIPDetail: '',
    OCIPorCCIP: '',
    OriginalContractValue: '',
    OtherLocations: '',
    PrimaryDocumentationApplication: '',
    PrimaryProjectCounty: '',
    PrimaryProjectCountyCode: '',
    PrimaryProjectLocation: '',
    PrimaryProjectState: '',
    ProjectRisks: '',
    ProjectRole: '',
    ProjectTeam: '',
    SpecializedApps: '',
    Subcontractors: [],
    TaxableStatus: '',
    TaxExemptNumber: '',
    TypeOfWork: '',
    WorkCategories: '',
    WorkType: ''
  }
});

export const _Defaults = atom({
  key: 'CompanyDefaults',
  default: {
    ContractGLRate: 0.01,
    RetentionPercent: 0,
    RetentionTerms: '',
    DSOModel: 2,
    LaborEquipOther: 0,
    Materials: 1,
    Subs: 2,
    Variance: 0.05
  }
});
export const _StatusMonth = selector({
  key: 'StatusMonth',
  get: async () => {
    const me = await getStatusMonth();
    return me;
  }
});

export const _SecurityLevel = atom({
  key: 'SecurityLevel',
  default: 'Unauthorized'
});

export const _Preferences = atom({
  key: 'Preferences',
  default: []
});

export const _Clickstream = atom({
  key: 'Clickstream',
  default: { currentStep: -1, steps: [], inAgenda: false, activeTask: -1, activeTaskName: '' }
});

export const _NotesModal = atom({
  key: 'NotesModal',
  default: { open: false, force: false },
  effects_UNSTABLE: [localStorageEffect('STATUS-NOTES', 'Notes')]
});

export const _MonthEndDate = atom({
  key: 'MonthEndDate',
  default: dayjs(new Date().setMonth(new Date().getMonth() - 1))
    .endOf('month')
    .format('YYYY-MM-DD'),
  effects_UNSTABLE: [localStorageEffect('STATUS-MONTHEND', 'MonthEnd')]
});

export const _MonthToPullFrom = selector({
  key: 'MonthToPullFrom',
  get: async ({ get }) => {
    const MonthEnd = get(_MonthEndDate);
    const CurrentJobID = get(_CurrentJob).id;
    const me = await getMostRecentMonth(CurrentJobID, MonthEnd);
    return me;
  }
});

export const _ServiceWorkerUpdate = atom({
  key: 'ServiceWorkerUpdate',
  default: false
});
export const _AppVersion = atom({
  key: 'AppVersion',
  default: '1.0.38'
});

export const _LaborForecastingGridState = atom({
  key: 'LaborForecastingGridState',
  default: {},
  effects_UNSTABLE: [localStorageEffect('STATUS-FORECASTING', 'LaborForecastingGridState')]
});
export const _MaterialForecastingGridState = atom({
  key: 'MaterialForecastingGridState',
  default: {},
  effects_UNSTABLE: [localStorageEffect('STATUS-FORECASTING', 'MaterialForecastingGridState')]
});
export const _SubcontractorForecastingGridState = atom({
  key: 'SubcontractorForecastingGridState',
  default: {},
  effects_UNSTABLE: [localStorageEffect('STATUS-FORECASTING', 'SubcontractorForecastingGridState')]
});
export const _EquipmentForecastingGridState = atom({
  key: 'EquipmentForecastingGridState',
  default: {},
  effects_UNSTABLE: [localStorageEffect('STATUS-FORECASTING', 'EquipmentForecastingGridState')]
});
export const _OtherForecastingGridState = atom({
  key: 'OtherForecastingGridState',
  default: {},
  effects_UNSTABLE: [localStorageEffect('STATUS-FORECASTING', 'OtherForecastingGridState')]
});

export const _InitedOffline = atom({
  key: 'InitedOffline',
  default: { value: false, lastInited: new Date().toJSON() },
  effects_UNSTABLE: [SaveInitedOffline()]
});

export const _InitingOffline = atom({
  key: 'InitingOffline',
  default: false
});

export const _ShowChangelogModal = atom({
  key: 'ShowChangelogModal',
  default: false
});

export const _GridPagination = atom({
  key: 'GridPagination',
  default: true
});

export const _CostDiffThreshold = selector({
  key: 'CostDiffThreshold',
  get: async () => {
    const me = await getCostDiffValues();
    return me;
  }
});

export const _ChangeOrderGridState = atom({
  key: 'ChangeOrderGridState',
  default: {},
  effects_UNSTABLE: [localStorageEffect('STATUS-CHANGEORDER', 'ChangeOrderGridState')]
});
