import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { doc, getDoc } from 'firebase/firestore';
import { ListItemButton, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { existsWithLength } from '@aldridge/aldg-helpers';
import dayjs from 'dayjs';
import { _MonthEndDate } from '../../../../_Recoil/atoms';
import { firestore } from '../../../../firebase';
import { StatusContext } from '../../../../providers/StatusProvider';
import SHOK from '../ImageDisplay/SHOKLogo.png';
import { nonMuiThemes } from '../../../../theme';

const TalkingPoints = (props) => {
  const { jctdscid, type } = props;
  const [content, setContent] = useState({ type: '' });
  const [files, setFiles] = useState([]);
  const { attachments } = useContext(StatusContext);

  const mounted = useRef(null);

  const MonthEndDate = useRecoilValue(_MonthEndDate);

  const agendaDoc = doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${MonthEndDate}/Agenda`, 'TalkingPoints');

  useEffect(() => {
    mounted.current = true;
    getDoc(agendaDoc).then((d) => {
      if (mounted.current && d.exists()) setContent(d.data());
    });
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (existsWithLength(attachments) && mounted.current) {
      const filteredFiles = attachments
        ?.filter((file) => file.LinkTo === `${type} Talking Points` && file.monthEndDate === MonthEndDate)
        ?.sort((a, b) => (dayjs(a.uploadedDate).isBefore(dayjs(b.uploadedDate)) ? -1 : 1));
      if (mounted.current) setFiles(filteredFiles);
    } else if (mounted.current && typeof attachments !== 'undefined') setFiles([]);
  }, [attachments]);

  const previewFile = (file) => {
    const DEFAULT_OPTIONS = {
      toolbar: 'yes',
      location: 'yes',
      directories: 'yes',
      status: 'yes',
      menubar: 'yes',
      scrollbars: 'yes',
      resizable: 'yes',
      width: 500,
      height: 400,
      top: (o, w) => (w.innerHeight - o.height) / 2 + w.screenY,
      left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX
    };
    if (file.NeedsDriveViewer)
      window.open(`https://drive.google.com/viewerng/viewer?url=${encodeURIComponent(file.downloadURL)}`, '', DEFAULT_OPTIONS);
    else window.open(file.downloadURL, '', DEFAULT_OPTIONS);
  };

  return (
    <>
      <Typography variant='body1' style={{ whiteSpace: 'break-spaces', paddingBottom: '8px' }}>
        {content?.[type || '']}
      </Typography>
      {type === 'Safety' ? <img src={SHOK} srcSet={SHOK} alt='SHOKLogo' style={{ width: '60%' }} /> : null}
      {files
        ?.filter((d) => d.type === 'image/jpeg')
        .sort((a, b) => dayjs(a.uploadedDate).isBefore(dayjs(b.uploadedDate)))
        .map((file) => (
          <React.Fragment key={file.name}>
            <div style={nonMuiThemes.divider} />
            <img src={file.url} alt={file.name} key={file.name} style={{ width: '100%' }} />
          </React.Fragment>
        ))}
      <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
        {files
          ?.filter((d) => d.type !== 'image/jpeg')
          .sort((a, b) => dayjs(a.uploadedDate).isBefore(dayjs(b.uploadedDate)))
          .map((file) => (
            <li key={file.name}>
              <ListItemButton onClick={() => previewFile(file)}>
                <img src={file.url} alt={file.name} key={file.name} style={{ width: '10%', paddingRight: 5 }} />
                <Typography variant='body2' color='#3b78e7'>{`Click to Preview: ${file.name}`}</Typography>
              </ListItemButton>
            </li>
          ))}
      </ul>
    </>
  );
};

TalkingPoints.propTypes = {
  type: PropTypes.string,
  jctdscid: PropTypes.string.isRequired
};
TalkingPoints.defaultProps = {
  type: 'Safety'
};

export default TalkingPoints;
