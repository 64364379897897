/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Paper, Grid, Box, Tabs, Tab } from '@mui/material';
import { AldgQuickAdd, InputAutocomplete, InputSelect } from '@aldridge/aldg-data-components';
import { getDocs, query, collectionGroup, setDoc, doc, deleteDoc, getDoc } from 'firebase/firestore';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import ErrorBoundary from '../_GlobalComponents/ErrorBoundary';
import PageHeader from '../Components/UIComponents/PageHeader';
import { config, firestore } from '../firebase';

const UserAssignmentApp = () => {
  const [Loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const apiRef = useGridApiRef();
  const [currentRecord, setCurrentRecord] = useState({});

  const isGlobalRole = (role) => ['Executive', 'Quality', 'Agile', 'Project Accountant'].includes(role);
  const isDivisionalRole = (role) => ['Division'].includes(role);

  const optsList = (idx, labelOnly = false) => {
    let opts = [];
    if (idx === 0) {
      opts = [
        { value: 'Editor', label: 'Editor' },
        { value: 'Viewer', label: 'Viewer' }
      ];
    }
    if (idx === 1) {
      opts = [{ value: 'Division', label: 'Division' }];
    }
    if (idx === 2) {
      opts = [
        { value: 'Executive', label: 'Executive (Global)' },
        { value: 'Agile', label: 'Agile (Global)' },
        { value: 'Quality', label: 'Quality (Global)' },
        { value: 'Project Accountant', label: 'Project Accountant (Global)' }
      ];
    }

    if (labelOnly) return opts.map((o) => o.value);

    return opts;
  };

  const getJobAccess = (mounted) => {
    getDocs(query(collectionGroup(firestore, `UserAssignment`))).then((docs) => {
      const allUsers = [];
      docs.forEach((d) => {
        const userData = d.data();
        userData.id = d.id;
        try {
          // eslint-disable-next-line prefer-destructuring
          userData.JobDisplay = d.ref.path.split('/')[1];
        } catch (err) {
          userData.JobDisplay = '';
        } finally {
          allUsers.push(userData);
        }
      });

      setUserList(allUsers?.sort((a, b) => (a.nameDisplay || '')?.localeCompare(b.nameDisplay || '')));

      if (mounted) setLoading(false);
    });
  };

  const getDivisionalAccess = (mounted) => {
    getDocs(query(collectionGroup(firestore, `Users`))).then((userDocs) => {
      const allUsers = [];
      userDocs.forEach((uDoc) => {
        const user = uDoc.data();
        const { globalRole, Divisions } = user;
        if (existsWithLength(globalRole) && isDivisionalRole(globalRole)) {
          Divisions.forEach((div) => {
            allUsers.push({
              Division: [div],
              DivisionDisplay: div,
              name: [user.email],
              roleDisplay: globalRole,
              CosentialJobName: '',
              nameDisplay: user.name,
              ModifiedBy: 'ECMS',
              role: globalRole,
              isGlobal: true,
              Job: [],
              Email: user.email,
              id: `${user.email}-${globalRole}`
            });
          });
        }
      });
      if (mounted) {
        setUserList(allUsers?.sort((a, b) => (a.nameDisplay || '')?.localeCompare(b.nameDisplay || '')));
      }
      if (mounted) setLoading(false);
    });
  };

  const getGlobalAccess = (mounted) => {
    getDocs(query(collectionGroup(firestore, `Users`))).then((userDocs) => {
      const allUsers = [];
      userDocs.forEach((uDoc) => {
        const user = uDoc.data();
        const { globalRole } = user;
        if (existsWithLength(globalRole) && isGlobalRole(globalRole)) {
          allUsers.push({
            JobDisplay: '',
            name: [user.email],
            roleDisplay: globalRole,
            CosentialJobName: '',
            nameDisplay: user.name,
            ModifiedBy: 'ECMS',
            role: globalRole,
            isGlobal: true,
            Job: [],
            Email: user.email,
            id: `${user.email}-${globalRole}`
          });
        }
      });
      if (mounted) {
        setUserList(allUsers?.sort((a, b) => (a.nameDisplay || '')?.localeCompare(b.nameDisplay || '')));
      }
      if (mounted) setLoading(false);
    });
  };

  const refreshData = () => {
    let mounted = true;
    if (existsWithLength(apiRef.current)) apiRef.current.setColumnVisibilityModel({ isGlobal: false });

    if (mounted) setLoading(true);
    if (tabValue === 0) {
      getJobAccess(mounted);
    }
    if (tabValue === 1) {
      getDivisionalAccess(mounted);
    }
    if (tabValue === 2) {
      getGlobalAccess(mounted);
    }

    return () => {
      mounted = false;
    };
  };
  useEffect(() => {
    refreshData();
  }, [tabValue]);

  const deleteDocument = async (db, row, Recordid) => {
    console.log('Recordid', Recordid);
    if (tabValue === 0) {
      await deleteDoc(doc(firestore, `ENT-Jobs/${row.Job[0]}/UserAssignment`, Recordid));
    }
    if (tabValue === 1) {
      const docRef = doc(firestore, `Users/${row.Email}`);
      const docc = await getDoc(docRef);
      const data = docc.data();
      data.Divisions = data.Divisions.filter((div) => div !== row.Division[0]);
      await setDoc(docRef, { ...data }, { merge: true });
    }
    if (tabValue === 2) {
      const docRef = doc(firestore, `Users/${row.Email}`);
      const docc = await getDoc(docRef);
      const data = docc.data();
      data.globalRole = 'none';
      await setDoc(docRef, { ...data }, { merge: true });
    }
    refreshData();
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      valueGetter: (params) => params.row.nameDisplay?.split('[')[0],
      flex: 50,
      canEdit: true,
      inputType: 'InputAutocomplete',
      config,
      firestoreOptions: {
        valueKey: 'prtmstid',
        collection: 'ENT-Employees',
        orderBy: 'PreferredLastName',
        method: 'get',
        where: [['Status', '==', 'A']]
      },
      optionDisplay: (opt) => `${opt.PreferredFirstName || ''} ${opt.MiddleName || ''} ${opt.PreferredLastName || ''} [${opt.UniversalID || ''}]`,
      autorecordFields: ['Email']
    },
    {
      field: 'Email',
      headerName: 'Email',
      valueGetter: (params) => params.row.Email,
      flex: 50
    },
    tabValue === 0
      ? {
          field: 'Job',
          inputType: 'InputAutocomplete',
          valueGetter: (params) => params.row.JobDisplay,
          flex: 50,
          canEdit: true,
          config,
          firestoreOptions: {
            valueKey: 'id',
            collection: 'ENT-Jobs',
            orderBy: 'id',
            method: 'get',
            where: [['EcmsStatusSimplified', '==', 'Open']]
          },
          autorecordFields: ['CosentialJobName'],
          optionDisplay: (opt) => `${opt.id}`
        }
      : undefined,
    tabValue === 1
      ? {
          field: 'Division',
          inputType: 'InputAutocomplete',
          valueGetter: (params) => params.row.DivisionDisplay,
          flex: 50,
          canEdit: true,
          config,
          firestoreOptions: {
            valueKey: 'id',
            collection: 'ENT-Divisions',
            orderBy: 'id',
            method: 'get',
            where: [['Active', '==', 'True']]
          },
          optionDisplay: (opt) => `${opt.DivisionName}`
        }
      : undefined,
    tabValue !== 1
      ? {
          field: 'role',
          headerName: 'Role',
          canEdit: 'true',
          inputType: 'InputSelect',
          options: optsList(tabValue),
          type: 'singleSelect',
          valueOptions: optsList(tabValue, true),
          onChangeDependents: ['isGlobal'],
          flex: 50
        }
      : undefined,
    {
      field: 'isGlobal',
      valueGetter: (params) => isGlobalRole(params.row.role),
      type: 'boolean',
      hideable: false
    },
    {
      field: 'Delete',
      headerName: '',
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <ToolTipIconButton
            shape='circle'
            tooltipText='Delete'
            color='error'
            icon={faTrash}
            onClick={() => {
              const id = params.row.id;
              deleteDocument(firestore, params.row, id);
            }}
          />
        </div>
      ),
      flex: 40
    }
  ].filter((a) => typeof a !== 'undefined');

  const getRowId = (params) => {
    if (existsWithLength(params.Division) && existsWithLength(params.Email)) return `${params.Division[0]}-${params.Email}-${params.role}`;
    return params.id;
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const a11yProps = (index, url) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    'data-url': url
  });

  const handleSave = () => {
    if (tabValue === 2) {
      setDoc(
        doc(firestore, 'Users', `${currentRecord.Email}`),
        { globalRole: currentRecord.role, email: currentRecord.Email, dateModified: new Date() },
        { merge: true }
      );
    } else if (tabValue === 1) {
      getDoc(doc(firestore, 'Users', `${currentRecord.Email}`)).then((d) => {
        const data = d.data();
        data.Divisions = [...(data.Divisions || []), currentRecord.division[0]];
        data.Divisions = [...new Set(data.Divisions)];
        setDoc(d.ref, { ...data, globalRole: 'Division' }, { merge: true });
      });
    } else {
      console.log(currentRecord);
      setDoc(
        doc(firestore, `ENT-Jobs/${currentRecord.job}/UserAssignment`, `${currentRecord.job}-${currentRecord.Email}`),
        {
          Email: currentRecord.Email,
          Job: currentRecord.job,
          JobDisplay: currentRecord.job,
          CosentialJobName: currentRecord.CosentialJobName,
          name: currentRecord.name,
          nameDisplay: currentRecord.nameDisplay,
          role: currentRecord.role,
          isGlobal: false,
          id: `${currentRecord.job}-${currentRecord.Email}-${currentRecord.role}`
        },
        {
          merge: true
        }
      );
    }
    setCurrentRecord((prev) => ({ ...prev, name: '', Email: '', job: '', division: '', role: '' }));
    refreshData();
  };

  const UpdateRow = (row) => {
    console.log('row', row);
    if (tabValue === 0) {
      setCurrentRecord({
        id: row.id,
        name: row.name,
        Email: row.Email,
        nameDisplay: row.nameDisplay,
        job: row.Job,
        CosentialJobName: row.CosentialJobName,
        role: row.role
      });
    }
    if (tabValue === 1) {
      setCurrentRecord({
        id: row.id,
        name: row.name,
        Email: row.Email,
        divisionDisplay: row.DivisionDisplay,
        division: row.Division,
        role: row.role,
        roleDisplay: row.roleDisplay
      });
    }
    if (tabValue === 2) {
      setCurrentRecord({
        id: row.id,
        Email: row.Email,
        name: row.name,
        nameDisplay: row.nameDisplay,
        role: row.role,
        roleDisplay: row.roleDisplay
      });
    }
  };

  return (
    <ErrorBoundary componentName='User Assignment (APP)'>
      <Paper variant='outlined' className='aldg-field'>
        <Grid container>
          <PageHeader title='User Assignment' />
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChange} aria-label='basic tabs example' variant='fullWidth' centered>
                <Tab label='Job Specific' {...a11yProps(0, 'Job Specific')} />
                <Tab label='Divisional' {...a11yProps(1, 'Divisional')} />
                <Tab label='Global' {...a11yProps(2, 'Global')} />
              </Tabs>
            </Box>
            <Grid container justifyContent='center'>
              <AldgQuickAdd
                DataGridProps={{
                  apiRef,
                  pagination: true,
                  getRowId,
                  loading: Loading,
                  onCellClick: (cell, event) => {
                    if (cell.field === 'Delete') {
                      event.preventDefault();
                      event.stopPropagation();
                    }
                  }
                }}
                anchor='right'
                updateRow={UpdateRow}
                columns={columns}
                rows={userList}
                handleSave={handleSave}
                form={
                  <>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={1}>
                      <Grid item xs={12}>
                        <InputAutocomplete
                          firestoreOptions={{
                            valueKey: tabValue === 0 ? 'prtmstid' : 'Email',
                            collection: 'ENT-Employees',
                            orderBy: 'PreferredLastName',
                            where: [['Status', '==', 'A']],
                            method: 'onSnapshot'
                          }}
                          label='Name'
                          name='name'
                          onChange={(x, y, z, a) => {
                            setCurrentRecord((prev) => ({
                              ...prev,
                              name: x,
                              nameDisplay:
                                `${a?.[0]?.PreferredFirstName} ${a?.[0]?.MiddleName} ${a?.[0]?.PreferredLastName} [${a?.[0]?.Title}]` || '',
                              Email: a?.[0]?.Email || ''
                            }));
                          }}
                          optionDisplay={(opt) => `${opt.PreferredFirstName} ${opt.MiddleName} ${opt.PreferredLastName} [${opt.Title}]`}
                          value={currentRecord.name || []}
                          disablePortal
                        />
                      </Grid>
                      {tabValue === 0 ? (
                        <Grid item xs={12}>
                          <InputAutocomplete
                            firestoreOptions={{
                              valueKey: 'id',
                              collection: 'ENT-Jobs',
                              where: [
                                ['EcmsStatusSimplified', '==', 'Open'],
                                ['EcmsSubJobNumber', '==', '   ']
                              ],
                              orderBy: 'id',
                              method: 'onSnapshot'
                            }}
                            label='Job'
                            name='Job'
                            onChange={(x, y, z, a) => {
                              setCurrentRecord((prev) => ({ ...prev, job: x, CosentialJobName: a?.[0]?.CosentialJobName || '' }));
                            }}
                            optionDisplay={(opt) => `${opt.id} - ${opt.CosentialJobName || opt.EcmsJobDescription || ''} `}
                            value={currentRecord.job || []}
                            disablePortal
                          />
                        </Grid>
                      ) : null}
                      {tabValue === 1 ? (
                        <Grid item xs={12}>
                          <InputAutocomplete
                            firestoreOptions={{
                              valueKey: 'id',
                              collection: 'ENT-Divisions',
                              orderBy: 'id',
                              method: 'get',
                              where: [['Active', '==', 'True']]
                            }}
                            optionDisplay={(opt) => `${opt.DivisionName}`}
                            label='Division'
                            name='Division'
                            onChange={(x) => {
                              setCurrentRecord((prev) => ({ ...prev, division: x }));
                            }}
                            value={currentRecord.division || []}
                            disablePortal
                          />
                        </Grid>
                      ) : null}

                      {tabValue !== 1 ? (
                        <Grid item xs={12}>
                          <InputSelect
                            options={optsList(tabValue)}
                            fullWidth
                            label='Role'
                            value={currentRecord.role}
                            onChange={(x) => {
                              setCurrentRecord((prev) => ({ ...prev, role: x.target.value }));
                            }}
                            id='fullWidth'
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ErrorBoundary>
  );
};
export default UserAssignmentApp;
