import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import { getDocs, query, collection, orderBy, where } from 'firebase/firestore';
import * as ExcelJS from 'exceljs';
import { TailSpin } from 'react-loader-spinner';
import { useRecoilValue } from 'recoil';
import { _Defaults, _MonthEndDate } from '../../../_Recoil/atoms';
import fileUploadClick from '../../../utils/fileUploadClick';
import { UserContext } from '../../../providers/UserProvider';
import PromptUpload from '../../UIComponents/PromptUpload';
import { firestore } from '../../../firebase';
import UploadChangeOrder from './UploadChangeOrder';
import DownloadChangeOrder from './DownloadChangeOrder';
import uploadAttachment from '../Attachments/uploadAttachment';
import { StatusContext } from '../../../providers/StatusProvider';

const DownloadUploadTemplate = (props) => {
  const { recordid, record, setRecord, Transaction, memorialized, editing, setRoundingValues } = props;
  const [uploadingBudget, setUploadingBudget] = useState(false);
  const [attachPrompted, setAttachedPrompted] = useState(false);
  const Defaults = useRecoilValue(_Defaults);
  const user = useContext(UserContext);
  const MonthEndDate = useRecoilValue(_MonthEndDate);
  const { loadAttachments } = useContext(StatusContext);

  const [Segments, setSegments] = useState([]);

  const UploadBudget = async (toSave) => {
    // Update all the forecasts (only update the To Complete Fields)
    const callback = (filedata) => {
      const workbook = new ExcelJS.Workbook();
      setUploadingBudget(true);
      setRoundingValues([]);
      workbook.xlsx.load(filedata).then(() => UploadChangeOrder(record, setRecord, workbook, setUploadingBudget, Segments, setRoundingValues));
    };
    const fileCallback = (files) => {
      if (toSave) uploadAttachment(files, recordid, true, loadAttachments, () => {}, user, MonthEndDate, 'Budgets');
      setAttachedPrompted(false);
    };
    fileUploadClick('uploadbudget', callback, fileCallback);
  };

  useEffect(() => {
    let mounted = true;
    getDocs(query(collection(firestore, 'ENT-Segments'), where('Status', '==', 'Yes'), orderBy('SegmentValue'))).then((d) => {
      const all = [];
      d.forEach((dd) => all.push(dd.data()));
      if (mounted) setSegments(all);
    });
    return () => {
      mounted = false;
    };
  }, []);

  return uploadingBudget || Segments.length <= 0 ? (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <TailSpin height={56} />
    </div>
  ) : (
    <>
      <Grid item xs={12} md={6} style={{ textAlign: 'right', paddingRight: '8px' }}>
        <Button
          variant='contained'
          onClick={() => DownloadChangeOrder(recordid, record, Segments, Transaction.ProjectManager, Transaction.ContractGLRate, Defaults)}
        >
          Download XLSX File
        </Button>
      </Grid>
      {!memorialized && (
        <Grid item xs={12} md={6} style={{ textAlign: 'left', paddingLeft: '8px' }}>
          {editing && !attachPrompted && (
            <Button variant='contained' onClick={() => setAttachedPrompted('prompted')}>
              Upload Budgets
            </Button>
          )}
          {editing && attachPrompted && (
            <div style={{ marginTop: '-24px' }}>
              <PromptUpload upload={UploadBudget} setAttachedPrompted={setAttachedPrompted} />
            </div>
          )}
        </Grid>
      )}
    </>
  );
};

DownloadUploadTemplate.propTypes = {
  recordid: PropTypes.string.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  setRecord: PropTypes.func.isRequired,
  Transaction: PropTypes.objectOf(PropTypes.any),
  memorialized: PropTypes.bool,
  editing: PropTypes.bool,
  setRoundingValues: PropTypes.func
};
DownloadUploadTemplate.defaultProps = {
  Transaction: {},
  memorialized: false,
  editing: true,
  setRoundingValues: () => {}
};

export default DownloadUploadTemplate;
