/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { collection, getDocs, orderBy, query, where, doc, setDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { AldgQuickAdd, InputTextarea } from '@aldridge/aldg-data-components';
import { useRecoilValue } from 'recoil';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { Grid, Typography } from '@mui/material';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { _Clickstream, _MonthEndDate, _SecurityLevel } from '../../../_Recoil/atoms';
import { UserContext } from '../../../providers/UserProvider';
import existsWithLength from '../../../utils/existsWithLength';
import { getCurrentMEDate } from '../../../utils/monthEndOptions';
import { firestore } from '../../../firebase';
import { AdminUp, DivisionalUp, EditorUp, PMUp, SuperAdmin } from '../UserAssignment/checkSecurity';
import LinkToObj from './LinkToObj';

const NotesList = (props) => {
  const { jctdscid, memorialized, monthEndDate, prevMemorialized, NextFromStatusMonth, isStatusMonth } = props;
  const SecurityLevel = useRecoilValue(_SecurityLevel);
  const Clickstream = useRecoilValue(_Clickstream);
  const user = useContext(UserContext);
  const [Notes, setNotes] = useState([]);
  const [GridNotes, setGridNotes] = useState([]);
  const [rows, setRows] = useState([]);
  const [currentRecord, setCurrentRecord] = useState({
    MonthEnd: '',
    NoteAuthor: '',
    NoteOwner: '',
    Notes: '',
    Role: '',
    jctdscid: '',
    link: '',
    id: ''
  });

  const inAgenda = Clickstream.inAgenda;
  const NextMonthEdit = prevMemorialized && monthEndDate === NextFromStatusMonth; // Can edit next month if status month is memorialized
  const StatusMonthEdit = isStatusMonth || NextMonthEdit || AdminUp(SecurityLevel); // Can edit if status month or above condition (or admin)
  const canEdit = !inAgenda && !memorialized && StatusMonthEdit && EditorUp(SecurityLevel);
  const apiRef = useGridApiRef();

  const deleteDocument = async (row, id) => {
    await deleteDoc(doc(firestore, `ENT-Jobs/${jctdscid}/Notes`, id));
  };

  const columns = [
    // {
    //   field: 'jctdscid',
    //   valueGetter: (params) => params.row.jctdscid,
    //   defaultValue: jctdscid
    // },
    // {
    //   field: 'NoteOwner',
    //   valueGetter: (params) => params.row.NoteOwner,
    //   defaultValue: user.email
    // },
    // {
    //   field: 'Role',
    //   valueGetter: (params) => params.row.Role,
    //   defaultValue: SecurityLevel
    // },
    {
      field: 'MonthEnd',
      headerName: 'Month End',
      valueGetter: (params) => params.row.MonthEnd,
      defaultValue: monthEndDate,
      flex: 30
    },
    {
      field: 'NoteAuthor',
      headerName: 'Author',
      valueGetter: (params) => params.row.NoteAuthor,
      defaultValue: user.name,
      flex: 30
    },
    {
      field: 'Notes',
      headerName: 'Note',
      canEdit: (params) => getCurrentMEDate(params.row.MonthEnd) === monthEndDate && params.row.NoteOwner === user.email,
      inputType: 'InputTextarea',
      onChangeDependents: ['MonthEnd', 'NoteOwner', 'jctdscid'],
      flex: 150
    },
    LinkToObj,
    {
      field: 'Delete',
      headerName: '',
      renderCell: (params) => {
        if (params.row.ecmsRole !== true && (params.row.NoteOwner === user.email || AdminUp(SecurityLevel))) {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <ToolTipIconButton
                shape='circle'
                tooltipText='Delete'
                color='error'
                icon={faTrash}
                onClick={() => {
                  const id = params.row.id;
                  deleteDocument(params.row, id);
                }}
              />
            </div>
          );
        }
        return null;
      },
      flex: 40
    }
  ];

  useEffect(() => {
    let mounted = true;
    let q = query(collection(firestore, `ENT-Jobs/${jctdscid}/Notes`));
    let qGrid = query(collection(firestore, `ENT-Jobs/${jctdscid}/AldgDataGridNotes`));
    // super admins see all
    if (!SuperAdmin(SecurityLevel)) {
      if (AdminUp(SecurityLevel)) {
        q = query(q, where('Role', '!=', 'SuperAdmin'), orderBy('Role', 'desc'));
        qGrid = query(qGrid, where('userRole', '!=', 'SuperAdmin'), orderBy('userRole', 'desc'));
      } else if (DivisionalUp(SecurityLevel)) {
        q = query(q, where('Role', 'not-in', ['Admin', 'SuperAdmin']), orderBy('Role', 'desc')); // execs see all but admins
        qGrid = query(qGrid, where('userRole', 'not-in', ['Admin', 'SuperAdmin']), orderBy('userRole', 'desc'));
      } else if (PMUp(SecurityLevel) || EditorUp(SecurityLevel)) {
        q = query(q, where('Role', 'in', ['Editor', 'PM'])); // pms see pm and editor notes
        qGrid = query(qGrid, where('userRole', 'in', ['Editor', 'PM']));
      }
      // else if (EditorUp(SecurityLevel)) {
      //   q = query(q, where('Role', '==', 'Editor')); // editors only see editor notes
      //   qGrid = query(qGrid, where('userRole', '==', 'Editor'));
      // }
    }

    q = query(q, orderBy('MonthEnd', 'desc'));
    onSnapshot(q, (snap) => {
      const all = [];
      snap.forEach((d) => all.push(d.data()));
      if (mounted) setNotes(all?.sort((a, b) => new Date(a.MonthEnd) - new Date(b.MonthEnd)));
    });

    qGrid = query(qGrid, orderBy('meta.MonthEndDate', 'desc'));
    getDocs(qGrid).then((snap) => {
      const all = [];
      const jid = jctdscid;
      snap.forEach((d) => {
        const dt = d.data();
        // const dataOrigin = `${dt.id.split('-')[0]}${dt.meta.Page === 'Cash Flow' ? dt.id.split('-')[1] : ''}/${dt.id.split('-')[dt.meta.Page === 'Cash Flow' ? 2 : 1] }`;
        const note = {
          id: dt.id.toString(),
          CreatedBy: dt.userEmail,
          CreatedDate: dt.createdTime,
          LinkTo: `/Job/${jid}/${dt.meta.Page.replaceAll(' ', '').replace('Page', '')}`,
          ModifiedBy: '',
          ModifiedDate: '',
          MonthEnd: dt.meta.MonthEndDate,
          NoteAuthor: dt.userName,
          NoteOwner: dt.userEmail,
          Notes: `${dt.dataGridName.toUpperCase()}: ${dt.content}`, // - ${dataOrigin.toUpperCase()}
          Role: dt.userRole,
          jctdscid: jid
        };
        all.push(note);
      });
      if (mounted) setGridNotes(all?.sort((a, b) => new Date(a.MonthEnd) - new Date(b.MonthEnd)));
    });
    return () => {
      mounted = false;
    };
  }, [jctdscid]);

  useEffect(() => {
    if (existsWithLength(apiRef.current)) apiRef.current.setColumnVisibilityModel({ jctdscid: false, NoteOwner: false, Role: false });
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) setRows(Notes.concat(GridNotes));
    return () => {
      mounted = false;
    };
  }, [Notes, GridNotes]);

  useEffect(() => {
    if (rows.length > 0) {
      const sorted = rows?.sort((a, b) => new Date(b.MonthEnd) - new Date(a.MonthEnd));
      setRows(sorted);
    }
  }, [rows]);

  const UpdateRow = (row) => {
    setCurrentRecord({
      MonthEnd: row.MonthEnd,
      NoteAuthor: user.name,
      NoteOwner: row.NoteOwner,
      Notes: row.Notes,
      Role: row.Role,
      jctdscid: row.jctdscid,
      link: row.link,
      id: row.id.toString(),
      CreatedDate: row.CreatedDate,
      CreatedBy: row.CreatedBy,
      ModifiedDate: new Date().toJSON(),
      ModifiedBy: user.email
    });
  };

  const handleSave = async () => {
    const id = existsWithLength(currentRecord.id) ? currentRecord.id : Date.now().toString();
    await setDoc(
      doc(firestore, `ENT-Jobs/${jctdscid}/Notes`, id),
      {
        MonthEnd: monthEndDate,
        NoteAuthor: user.name,
        NoteOwner: user.email,
        Notes: currentRecord.Notes,
        Role: SecurityLevel,
        jctdscid,
        link: '',
        id,
        CreatedDate: new Date().toJSON(),
        CreatedBy: user.email,
        ModifiedDate: new Date().toJSON(),
        ModifiedBy: user.email
      },
      { merge: true }
    );
    setCurrentRecord({
      MonthEnd: '',
      NoteAuthor: '',
      NoteOwner: '',
      Notes: '',
      Role: '',
      jctdscid: '',
      link: '',
      id: ''
    });
  };

  const handleCancel = () => {
    setCurrentRecord({
      MonthEnd: '',
      NoteAuthor: '',
      NoteOwner: '',
      Notes: '',
      Role: '',
      jctdscid: '',
      link: '',
      id: ''
    });
  };

  return (
    <>
      <Grid container justifyContent='center' />
      <AldgQuickAdd
        DataGridProps={{
          apiRef,
          pagination: true,
          getRowID: (params) => params.row.id || Date.now().toString(),
          onCellClick: (cell, event) => {
            if (cell.field === 'Delete') {
              event.preventDefault();
              event.stopPropagation();
            }
          }
        }}
        anchor='right'
        updateRow={UpdateRow}
        columns={columns}
        rows={Notes}
        handleSave={handleSave}
        handleCancel={handleCancel}
        form={
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={1}>
            <Grid item xs={12}>
              {currentRecord.NoteOwner === user.email || AdminUp(SecurityLevel) ? (
                <InputTextarea
                  fullWidth
                  label='Note'
                  value={currentRecord.Notes || ''}
                  onChange={(e) => setCurrentRecord({ ...currentRecord, Notes: e })}
                  id='fullWidth'
                />
              ) : (
                <>
                  <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {currentRecord.Notes}
                  </div>
                  <Typography variant='subtitle1' color='gray'>
                    **Only the owner of this note can edit this note.
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

NotesList.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  memorialized: PropTypes.bool,
  monthEndDate: PropTypes.string,
  prevMemorialized: PropTypes.bool,
  NextFromStatusMonth: PropTypes.string.isRequired,
  isStatusMonth: PropTypes.bool
};
NotesList.defaultProps = { memorialized: false, monthEndDate: '2010-12-31', prevMemorialized: false, isStatusMonth: false };

export default NotesList;
